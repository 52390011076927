import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../hooks/useTranslator";
import { haveValue, skipMaccUser } from "../../helper/common";
import { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import AuthContext from "../../context/auth/AuthContext";
import { Modal, ProgressBar } from "react-bootstrap";
import ProfileScore from "../Tenant/ProfileScore";
import { MdInfoOutline } from "react-icons/md";
import constants from "../../Utils/constants";
import TenantContext from "../../context/tenant/TenantContext";
const TenantSkor = (props) => {
  const { showControls = false } = props;
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const {
    loadSharedProfiles,
    fullProfileShared,
    fullProfileSharedLoaded,
    updateTenant,
  } = tenantContext;

  var { user } = authContext;
  if (haveValue(props.user)) {
    user = props.user;
  }
  const progressColor = "#F27833";
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [ieScore, setIeScore] = useState(0);
  const [progressBarPerc, setProgressBarPerc] = useState(0);
  const [skorModal, setSkorModal] = useState(false);
  const [shareProfileModal, setShareProfileModal] = useState(false);

  useEffect(() => {
    var temp = 0;
    if (user?.profile_completed) {
      temp += 25;
    }
    if (user?.docs_uploaded) {
      temp += 25;
    }
    if (user?.onfido_kyc_success) {
      temp += 25;
    }
    if (user?.gurantor_id?.paymentScores || user?.paymentScores) {
      temp += 25;
    }

    // setProgressBarPerc(
    //   haveValue(user?.gurantor_id?.paymentScores?.creditScore?.value)
    //     ? user?.gurantor_id?.paymentScores?.creditScore?.value > 800
    //       ? 800
    //       : user?.gurantor_id?.paymentScores?.creditScore?.value / 8
    //     : user?.ie_verified && user?.is_university
    //     ? (ieScore > 800 ? 800 : ieScore) / 8
    //     : user?.paymentScores?.creditScore?.value > 800
    //     ? 800
    //     : user?.paymentScores?.creditScore?.value / 8
    // );
    setProgressBarPerc(
      (haveValue(user?.gurantor_id?.paymentScores?.creditScore?.value)
        ? user?.gurantor_id?.paymentScores?.creditScore?.value
        : user?.ie_verified && user?.is_university
        ? ieScore
        : user?.paymentScores?.creditScore?.value) / 10
    );

    setProfileCompletion(temp);
    calculateIeScore();
  }, [user]);

  useEffect(() => {
    let progressbar = document.querySelector(
      ".CircularProgressbar .CircularProgressbar-path"
    );
    if (
      document.querySelector(".retal-score-meter .CircularProgressbar-path")
    ) {
      document
        .querySelector(".retal-score-meter .CircularProgressbar-path")
        .classList.add("rent-score");
      document
        .querySelector(".CircularProgressbar")
        .insertBefore(
          document.querySelector(
            ".retal-score-meter .CircularProgressbar-path"
          ),
          progressbar
        );
    }
  }, []);

  const navigateConnectBank = () => {
    navigate("/tenant/connectBank");
  };

  const checkSkorStatus = (user) => {
    const skor = haveValue(user?.gurantor_id?.paymentScores?.creditScore?.value)
      ? user?.gurantor_id?.paymentScores?.creditScore?.value
      : user?.ie_verified && user?.is_university
      ? ieScore
      : user?.paymentScores?.creditScore?.value;
    if (skor <= 200) {
      return <p className="UnstableText">{_t("Unstable")}</p>;
    } else if (skor >= 201 && skor <= 400) {
      return <p className="VariableText">{_t("Variable")}</p>;
    } else if (skor >= 401 && skor <= 600) {
      return <p className="StableText">{_t("Stable")}</p>;
    } else if (skor >= 601 && skor <= 800) {
      return <p className="ReliableText">{_t("Reliable")}</p>;
    } else if (skor >= 800) {
      return <p className="ExtremelyText">{_t("Extremely reliable")}</p>;
    }
  };

  const getGradient = (value) => {
    return `linear-gradient(to right, red ${
      value * 0.5
    }%, yellow ${value}%, green ${value}%)`;
  };

  const calculateIeScore = () => {
    var totalScore = (user?.paymentScores?.creditScore?.value || 0) / 2;

    if (user?.ie_verified && user?.is_university) {
      if (totalScore >= 600) {
        totalScore += 200;
      } else {
        totalScore += 400;
      }
    }
    if (totalScore > 850) {
      totalScore = 850;
    }
    setIeScore(totalScore);
  };

  useEffect(() => {
    const userid = localStorage.getItem("saveduserid");
    if (user && user?._id) {
      loadSharedProfiles(user?._id, "full");
    } else if (haveValue(userid)) {
      loadSharedProfiles(userid, "full");
    }
  }, []);

  const isProfileShared = (landlordid) => {
    if (fullProfileShared !== null) {
      for (const profileShared of fullProfileShared) {
        if (profileShared.landlordid === landlordid) {
          return true;
        }
      }
      if (user?.macc_auto_shared) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (user.user_type === constants.MACC) {
      if (fullProfileSharedLoaded) {
        //check if kyc done
        // if ((user?.onfido_kyc_success || user?.kyc_success) && showControls) {
        if (+user?.myScore >= 50 && showControls) {
          console.log("COMING INSIDE HERE");
          const landlordId = process.env.REACT_APP_MACC_LANDLORED_ID;
          const landlord = isProfileShared(landlordId);
          console.log("CHECK MAIN VAL HERE", landlord);
          if (!landlord) {
            // let profileNotShareBy = sessionStorage.getItem(
            //   "profile-not-share-by"
            // );
            // if (profileNotShareBy != user._id) {
            const data = {
              macc_auto_shared: true,
            };
            updateTenant(data);
            setShareProfileModal(true);
            // }
          }
        }
      }
    }
  }, [user, fullProfileSharedLoaded]);

  const shareProfileWithLandlord = () => {
    setShareProfileModal(false);
    const landlordId = process.env.REACT_APP_MACC_LANDLORED_ID;
    navigate(`/profile-shared?shareWith=${landlordId}&event=share_start`);
  };

  const cancelShareProfile = () => {
    setShareProfileModal(false);
    // sessionStorage.setItem("profile-not-share-by", user._id);
  };

  //buttons
  const isButtonShown = (btnName) => {
    let shouldShowButton = false;
    switch (btnName) {
      case "financial":
        const hasGuarantorCreditScore = haveValue(
          user?.gurantor_id?.paymentScores?.creditScore?.value
        );
        const hasUserCreditScore = haveValue(
          user?.paymentScores?.creditScore?.value
        );
        const isUniversityVerified = user?.ie_verified && user?.is_university;
        const isKycSuccessful = user?.kyc_success;
        const hasPaymentScores = haveValue(user?.paymentScores);

        shouldShowButton =
          hasGuarantorCreditScore ||
          hasUserCreditScore ||
          (isUniversityVerified
            ? isKycSuccessful
              ? hasPaymentScores
              : !hasPaymentScores
            : false);
        break;
      case "upload_documents":
        shouldShowButton =
          user && !skipMaccUser(user?.user_type) && user.docs_uploaded
            ? true
            : false;
        break;
      case "share_profile":
        shouldShowButton =
          user && (user?.onfido_kyc_success || user?.kyc_success)
            ? true
            : false;
        break;
      case "view_profile":
        shouldShowButton =
          user && (user?.onfido_kyc_success || user?.kyc_success)
            ? true
            : false;
        break;
      default:
        shouldShowButton = false;
    }

    return showControls && shouldShowButton;
  };

  return (
    <div className="pending-steps-wrapper">
      {haveValue(user?.gurantor_id?.paymentScores?.creditScore?.value) ||
      haveValue(user?.paymentScores?.creditScore?.value) ||
      (user?.ie_verified && user?.is_university
        ? user?.kyc_success
          ? haveValue(user?.paymentScores)
          : !haveValue(user?.paymentScores)
        : false) ? (
        <div className="tenant-skore">
          <div className="d-flex align-items-center justify-content-between mb-3 mb-md-4">
            <h3 className="skore-heading mb-0">Skor</h3>
            <div className="d-flex d-md-none gap-2">
              {
                // user?.ie_verified &&
                //   user?.is_university &&
                (user?.onfido_kyc_success || user?.kyc_success) &&
                  localStorage.getItem("usertype") == "tenant" &&
                  showControls && (
                    <div
                      className="icon-button"
                      onClick={(e) => navigate("/tenant/connectBank")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15.7184 3.03047C14.073 1.17422 11.6789 0 9 0C4.02891 0 0 4.02891 0 9H2.25C2.25 5.26992 5.26992 2.25 9 2.25C11.0566 2.25 12.8813 3.18164 14.1152 4.63359L11.9988 6.75H18V0.748828L15.7184 3.03047ZM9 15.75C6.94336 15.75 5.11875 14.8184 3.88477 13.3664L6.00117 11.25H0V17.2512L2.28164 14.9695C3.92695 16.8258 6.32461 18 9 18C13.9711 18 18 13.9711 18 9H15.75C15.75 12.7301 12.7301 15.75 9 15.75Z"
                          fill="#253837"
                        />
                      </svg>
                    </div>
                  )
              }
              {(user?.onfido_kyc_success || user?.kyc_success) &&
                localStorage.getItem("usertype") == "tenant" &&
                showControls && (
                  <div
                    className="icon-button"
                    onClick={(e) => navigate("/tenant/document#revisit")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 15 18"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.29032 0.230769C9.29032 0.169565 9.26585 0.110868 9.2223 0.0675908C9.17874 0.0243132 9.11966 0 9.05806 0H2.55484C1.87725 0 1.22742 0.267444 0.748295 0.743498C0.26917 1.21955 0 1.86522 0 2.53846V15.4615C0 16.1348 0.26917 16.7804 0.748295 17.2565C1.22742 17.7326 1.87725 18 2.55484 18H11.8452C12.5227 18 13.1726 17.7326 13.6517 17.2565C14.1308 16.7804 14.4 16.1348 14.4 15.4615V6.36646C14.4 6.30526 14.3755 6.24656 14.332 6.20328C14.2884 6.16001 14.2293 6.13569 14.1677 6.13569H9.9871C9.8023 6.13569 9.62507 6.06275 9.4944 5.93292C9.36373 5.80309 9.29032 5.627 9.29032 5.44338V0.230769ZM9.9871 9.23077C10.1719 9.23077 10.3491 9.30371 10.4798 9.43354C10.6105 9.56337 10.6839 9.73947 10.6839 9.92308C10.6839 10.1067 10.6105 10.2828 10.4798 10.4126C10.3491 10.5424 10.1719 10.6154 9.9871 10.6154H4.4129C4.22811 10.6154 4.05088 10.5424 3.92021 10.4126C3.78954 10.2828 3.71613 10.1067 3.71613 9.92308C3.71613 9.73947 3.78954 9.56337 3.92021 9.43354C4.05088 9.30371 4.22811 9.23077 4.4129 9.23077H9.9871ZM9.9871 12.9231C10.1719 12.9231 10.3491 12.996 10.4798 13.1258C10.6105 13.2557 10.6839 13.4318 10.6839 13.6154C10.6839 13.799 10.6105 13.9751 10.4798 14.1049C10.3491 14.2348 10.1719 14.3077 9.9871 14.3077H4.4129C4.22811 14.3077 4.05088 14.2348 3.92021 14.1049C3.78954 13.9751 3.71613 13.799 3.71613 13.6154C3.71613 13.4318 3.78954 13.2557 3.92021 13.1258C4.05088 12.996 4.22811 12.9231 4.4129 12.9231H9.9871Z"
                        fill="#253837"
                      />
                      <path
                        d="M10.6838 0.530257C10.6838 0.360411 10.8631 0.252411 10.996 0.358565C11.1084 0.449026 11.2097 0.554257 11.2961 0.674257L14.0952 4.54841C14.1584 4.63703 14.0897 4.75149 13.98 4.75149H10.9161C10.8545 4.75149 10.7954 4.72717 10.7519 4.6839C10.7083 4.64062 10.6838 4.58192 10.6838 4.52072V0.530257Z"
                        fill="#253837"
                      />
                    </svg>
                  </div>
                )}
            </div>
            {/* {localStorage.getItem("usertype") == "tenant" && showControls && (
              <Link to="/home">
                {_t("View your profile")}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                >
                  <path
                    d="M1 1L7.54748 7.00483C7.69083 7.13486 7.80465 7.2897 7.88233 7.46036C7.96 7.63103 8 7.81414 8 7.99909C8 8.18403 7.96 8.36714 7.88233 8.53781C7.80465 8.70848 7.69084 8.86331 7.54748 8.99334L1 15"
                    stroke="#7A7A7A"
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            )} */}
          </div>

          {(user?.onfido_kyc_success || user?.kyc_success) &&
            localStorage.getItem("usertype") == "tenant" &&
            showControls && (
              <div className="d-flex d-md-none mb-3">
                <button
                  className="btn btngreen d-inline-flex gap-3 align-items-center justify-content-center"
                  onClick={(e) => navigate("/profile-shared")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                  >
                    <path
                      d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z"
                      fill="black"
                    />
                  </svg>
                  <p>{_t("Share With Landlord")}</p>
                </button>
              </div>
            )}
          <div className="row score-board">
            <div className="col-lg-6">
              <div className="border-wrapper py-0">
                {/* <svg style={{ height: 0 }}>
                  <defs>
                    <linearGradient
                      id="progressGradient"
                      gradientTransform="rotate(90)"
                    >
                      <stop offset="0%" stopColor={"red"} />
                      <stop offset="50%" stopColor={"yellow"} />
                      <stop offset="100%" stopColor={"green"} />
                    </linearGradient>
                  </defs>
                </svg> */}
                <ProfileScore
                  creditScore={
                    haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    )
                      ? user?.gurantor_id?.paymentScores?.creditScore?.value
                      : user?.ie_verified && user?.is_university
                      ? ieScore
                      : user?.paymentScores?.creditScore?.value
                  }
                  rentScore={
                    user?.rental_skor < 200 ? user?.rental_skor || 0 : 200
                  }
                >
                  <h1>
                    {(haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    )
                      ? user?.gurantor_id?.paymentScores?.creditScore?.value
                      : user?.ie_verified && user?.is_university
                      ? ieScore
                      : user?.paymentScores?.creditScore?.value) +
                      (user?.rental_skor || 0)}
                  </h1>
                  <div className="d-flex gap-1 justify-content-center align-items-center">
                    {checkSkorStatus(user)}
                    <MdInfoOutline
                      style={{ color: "#EF7833", cursor: "pointer" }}
                      onClick={(e) => setSkorModal(true)}
                    />
                  </div>
                </ProfileScore>
                <div className="name-box-new">
                  <h3 className="score-name">
                    {haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    )
                      ? user?.gurantor_id?.kyc_details?.userDataByProvider[0]
                          ?.accounts[0]?.holderName
                      : haveValue(
                          user?.kyc_details?.userDataByProvider[0]?.accounts[0]
                            ?.holderName
                        )
                      ? user?.kyc_details?.userDataByProvider[0]?.accounts[0]
                          ?.holderName
                      : `${user?.ie_details_id?.fname} ${user?.ie_details_id?.lname}`}
                    {"*"}
                  </h3>
                  <ul className="score-textgray">
                    {user?.ie_verified &&
                    user?.is_university &&
                    !haveValue(
                      user?.gurantor_id?.paymentScores?.creditScore?.value
                    ) &&
                    !haveValue(user?.paymentScores?.creditScore?.value) ? (
                      <span>*{_t("Skor based on IE profile")} </span>
                    ) : (
                      <span>*{_t("Bank Account Used For Scoring")} </span>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="border-wrapper score-card">
                <h3 className="score-card-head">{_t("Skor Details")}:</h3>
                <div className="progress-bar-wrapper">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="progress-head">{_t("Financial Skor")}</p>
                    <p className="progress-score">
                      {haveValue(
                        user?.gurantor_id?.paymentScores?.creditScore?.value
                      )
                        ? user?.gurantor_id?.paymentScores?.creditScore?.value
                        : user?.ie_verified && user?.is_university
                        ? ieScore
                        : user?.paymentScores?.creditScore?.value}
                    </p>
                  </div>
                  <div className="progress-bar-inner-wrapper">
                    <div className="d-flex gap-2">
                      <p className="progress-step">
                        <span
                          style={{
                            width:
                              progressBarPerc < 20
                                ? `${progressBarPerc * 5}%`
                                : "100%",
                          }}
                          className="progress-fill progress-filler-red"
                        ></span>
                      </p>
                      <p className="progress-step">
                        <span
                          style={{
                            width:
                              progressBarPerc < 40
                                ? `${(progressBarPerc - 20) * 5}%`
                                : "100%",
                          }}
                          className="progress-fill progress-filler-orange"
                        ></span>
                      </p>
                      <p className="progress-step">
                        <span
                          style={{
                            width:
                              progressBarPerc < 60
                                ? `${(progressBarPerc - 40) * 5}%`
                                : "100%",
                          }}
                          className="progress-fill progress-filler-yellow"
                        ></span>
                      </p>
                      <p className="progress-step">
                        <span
                          style={{
                            width:
                              progressBarPerc < 80
                                ? `${(progressBarPerc - 60) * 5}%`
                                : "100%",
                          }}
                          className="progress-fill progress-filler-light-green"
                        ></span>
                      </p>
                      <p className="progress-step">
                        <span
                          style={{
                            width:
                              progressBarPerc < 100
                                ? `${(progressBarPerc - 80) * 5}%`
                                : "100%",
                          }}
                          className="progress-fill progress-filler-dark-green"
                        ></span>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="score-limit">0</span>
                      <span className="score-limit">1000</span>
                    </div>
                  </div>
                </div>
                <div className="progress-bar-wrapper">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="progress-head">{_t("Rental Skor")}</p>
                    <p className="progress-score rent-score-box">
                      {user?.rental_skor}
                    </p>
                  </div>
                  <div className="progress-bar-inner-wrapper">
                    <div className="progress">
                      <span
                        className="progress-simple-color"
                        style={{ width: `${user?.rental_skor / 2}%` }}
                      ></span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="score-limit">0</span>
                      <span className="score-limit">200</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex d-md-none justify-content-center align-items-center"
              onClick={(e) => navigate("/home")}
            >
              <p className="text-decoration-underline ms-3">
                {_t("View Details")}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="32px"
                viewBox="0 -960 960 960"
                width="32px"
                fill="#5f6368"
              >
                <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
              </svg>
            </div>
          </div>
        </div>
      ) : showControls ? (
        <div className="skore-details">
          <h3 className="skore-details-heading">Skor</h3>
          <div className="d-flex align-items-streach skore-dummmy">
            <div className="col-md-6 px-2">
              <div className="skore-image-wrapper border-wrapper py-0">
                <img src="/images/fetchingBank.svg" alt="" />
              </div>
            </div>
            <div className="col-md-6 px-2">
              <div className="skore-details-content border-wrapper py-3">
                <div className="d-flex gap-2 mb-2 align-items-center">
                  <img src="/images/home-icon.svg" alt="" />
                  <h3 className="skore-detail-heading">
                    {_t("Financial Check")}
                  </h3>
                </div>
                <p className="skor-details-desc">
                  {user?.have_gurantor == "true" &&
                  !haveValue(user?.gurantor_id)
                    ? _t("Waiting for your guarantor to score.")
                    : _t(
                        "Connecting your bank account boost your credibility. Most of the landlords select profiles with bank verification."
                      )}
                </p>
                {(user?.have_gurantor == "true" &&
                  !haveValue(user?.gurantor_id?.paymentScores)) ||
                  ((user?.kyc_success || user?.kyc_success == "true") &&
                  !haveValue(user?.paymentScores) ? (
                    <></>
                  ) : (
                    <div class="skore-details-points">
                      <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                      <span>50 {_t("Points")}</span>
                    </div>
                  ))}
                <button
                  onClick={navigateConnectBank}
                  className={`btn ${
                    (user?.have_gurantor == "true" &&
                      !haveValue(user?.gurantor_id?.paymentScores)) ||
                    ((user?.kyc_success || user?.kyc_success == "true") &&
                      !haveValue(user?.paymentScores))
                      ? "btnorange"
                      : "btngreen"
                  } complete-btn`}
                >
                  {(user?.have_gurantor == "true" &&
                    !haveValue(user?.gurantor_id?.paymentScores)) ||
                  ((user?.kyc_success || user?.kyc_success == "true") &&
                    !haveValue(user?.paymentScores))
                    ? _t("In Progress")
                    : _t("Complete")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className="col-12 d-none  d-md-flex justify-content-between btn-margin-set btn-combo-wrapper"
        style={{ paddingTop: "0.347vw" }}
      >
        <div className="d-md-flex gap-3 col-6">
          {isButtonShown("financial") && (
            <button
              className="btn step-go-home-btn score-below-btn btngrey"
              onClick={(e) => navigate("/tenant/connectBank")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15.7184 3.03047C14.073 1.17422 11.6789 0 9 0C4.02891 0 0 4.02891 0 9H2.25C2.25 5.26992 5.26992 2.25 9 2.25C11.0566 2.25 12.8813 3.18164 14.1152 4.63359L11.9988 6.75H18V0.748828L15.7184 3.03047ZM9 15.75C6.94336 15.75 5.11875 14.8184 3.88477 13.3664L6.00117 11.25H0V17.2512L2.28164 14.9695C3.92695 16.8258 6.32461 18 9 18C13.9711 18 18 13.9711 18 9H15.75C15.75 12.7301 12.7301 15.75 9 15.75Z"
                  fill="#253837"
                />
              </svg>
              {_t("Regenerate Financial Skor")}
            </button>
          )}
          {isButtonShown("upload_documents") && (
            <button
              className="btn btngrey score-below-btn"
              onClick={(e) => navigate("/tenant/document#revisit")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.29032 0.230769C9.29032 0.169565 9.26585 0.110868 9.2223 0.0675908C9.17874 0.0243132 9.11966 0 9.05806 0H2.55484C1.87725 0 1.22742 0.267444 0.748295 0.743498C0.26917 1.21955 0 1.86522 0 2.53846V15.4615C0 16.1348 0.26917 16.7804 0.748295 17.2565C1.22742 17.7326 1.87725 18 2.55484 18H11.8452C12.5227 18 13.1726 17.7326 13.6517 17.2565C14.1308 16.7804 14.4 16.1348 14.4 15.4615V6.36646C14.4 6.30526 14.3755 6.24656 14.332 6.20328C14.2884 6.16001 14.2293 6.13569 14.1677 6.13569H9.9871C9.8023 6.13569 9.62507 6.06275 9.4944 5.93292C9.36373 5.80309 9.29032 5.627 9.29032 5.44338V0.230769ZM9.9871 9.23077C10.1719 9.23077 10.3491 9.30371 10.4798 9.43354C10.6105 9.56337 10.6839 9.73947 10.6839 9.92308C10.6839 10.1067 10.6105 10.2828 10.4798 10.4126C10.3491 10.5424 10.1719 10.6154 9.9871 10.6154H4.4129C4.22811 10.6154 4.05088 10.5424 3.92021 10.4126C3.78954 10.2828 3.71613 10.1067 3.71613 9.92308C3.71613 9.73947 3.78954 9.56337 3.92021 9.43354C4.05088 9.30371 4.22811 9.23077 4.4129 9.23077H9.9871ZM9.9871 12.9231C10.1719 12.9231 10.3491 12.996 10.4798 13.1258C10.6105 13.2557 10.6839 13.4318 10.6839 13.6154C10.6839 13.799 10.6105 13.9751 10.4798 14.1049C10.3491 14.2348 10.1719 14.3077 9.9871 14.3077H4.4129C4.22811 14.3077 4.05088 14.2348 3.92021 14.1049C3.78954 13.9751 3.71613 13.799 3.71613 13.6154C3.71613 13.4318 3.78954 13.2557 3.92021 13.1258C4.05088 12.996 4.22811 12.9231 4.4129 12.9231H9.9871Z"
                  fill="#253837"
                />
                <path
                  d="M10.6838 0.530257C10.6838 0.360411 10.8631 0.252411 10.996 0.358565C11.1084 0.449026 11.2097 0.554257 11.2961 0.674257L14.0952 4.54841C14.1584 4.63703 14.0897 4.75149 13.98 4.75149H10.9161C10.8545 4.75149 10.7954 4.72717 10.7519 4.6839C10.7083 4.64062 10.6838 4.58192 10.6838 4.52072V0.530257Z"
                  fill="#253837"
                />
              </svg>
              {_t("Add Documents")}
            </button>
          )}
        </div>
        <div className="d-md-flex gap-3 col-6">
          {isButtonShown("view_profile") && (
            <button
              className="btn btngreen d-inline-flex gap-2 align-items-center score-below-btn"
              onClick={(e) => navigate("/home")}
            >
              {_t("View Your Profile")}
            </button>
          )}
          {isButtonShown("share_profile") && (
            <button
              className="btn btndarkgreen  d-inline-flex gap-2 align-items-center score-below-btn"
              onClick={(e) => navigate("/profile-shared")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
              >
                <path
                  d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z"
                  fill="#A3EB64"
                />
              </svg>
              {_t("Share Profile With Landlord")}
            </button>
          )}
        </div>
      </div>
      {/* Render Buttons End  */}
      <Modal
        show={skorModal}
        onHide={(e) => setSkorModal(false)}
        centered
        className="newmodalui modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("What Does your Skor Means")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="skor_info">
            <div className="d-flex justify-content-between redbg">
              <p>{"<200"}</p>
              <p className="UnstableText">{_t("Unstable")}</p>
            </div>
            <div className="d-flex justify-content-between pinkbg">
              <p>201-400</p>
              <p className="VariableText">{_t("Variable")}</p>
            </div>
            <div className="d-flex justify-content-between yellowbg">
              <p>401-600</p>
              <p className="StableText">{_t("Stable")}</p>
            </div>
            <div className="d-flex justify-content-between lightbg">
              <p>601-800</p>
              <p className="ReliableText">{_t("Reliable")}</p>
            </div>
            <div className="d-flex justify-content-between greenbg">
              <p>800-1000</p>
              <p className="ExtremelyText">{_t("Extremely reliable")}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={shareProfileModal}
        onHide={cancelShareProfile}
        centered
        className="newmodalui"
      >
        <Modal.Header className="set-cross-icon" closeButton>
          <Modal.Title className="modal-type"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {_t("You're halfway there!")}{" "}
            {_t(
              "You've completed 50% of your verification and your profile will now be shared with the landlord. Complete your full profile to stand out!"
            )}
          </p>
          <div className="btngroup row100 gridbar d-flex justify-content-center gap-3">
            <button
              className="btn btnorange modal-submit-btn mt-2"
              type="button"
              onClick={shareProfileWithLandlord}
            >
              {_t("Continue")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TenantSkor;
