import { useContext, useEffect, useState } from "react";
import useTranslator from "../../../hooks/useTranslator";
import axios from "axios";
import AuthContext from "../../../context/auth/AuthContext";
import { init } from "onfido-sdk-ui";
import { Container, Modal } from "react-bootstrap";
import TenantContext from "../../../context/tenant/TenantContext";
import { useNavigate } from "react-router-dom";
import { haveValue, skipMaccUser } from "../../../helper/common";
import constants from "../../../Utils/constants";

const TenantVerifyId = () => {
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { user, loadUser, userType } = authContext;
  const { onfidoKyc, updateKyc, updateTenant } = tenantContext;
  const { _t } = useTranslator();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const openBackModal = () => {
    setOpenModal(true);
  };

  const initiateOnfido = () => {
    setButtonDisable(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        setButtonDisable(false);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        setButtonDisable(false);
      });
  };

  const initiateKyc = (token, workFlowRunId) => {
    //console.log("On Fido Token = ", token);
    setButtonDisable(true);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      containerId: "onfido-mount",
      language: localStorage.getItem("i18nextLng") == "es" ? "es" : "en_US",
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        localStorage.removeItem("modal");
        console.log("everything is complete");

        setButtonDisable(false);
        saveOnfidoKycResponse(data);
        loadUser(userType);
        setCurrentStep(1);
      },
      onError: function (err) {
        setButtonDisable(false);
      },
      onUserExit: function (userExitCode) {
        console.log("user exitted", userExitCode);
        setButtonDisable(false);
      },
      onModalRequestClose: function () {
        onfido.setOptions({ isModalOpen: false });
        setButtonDisable(false);
        console.log("modal closed of onfido");
      },
      steps: ["welcome", "document", "face", "complete"],
    });
  };

  const saveOnfidoKycResponse = (kycData) => {
    onfidoKyc(user?._id, kycData);
  };

  const navigateBackHome = () => {
    navigate("/tenant");
  };

  const handleGobackReason = (reason) => {
    const data = {
      user_id: user?._id,
      exit_reason: reason,
    };
    updateKyc(data)
      .then((res) => {
        navigate("/tenant");
        console.log("Status updated");
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("reason", reason);
  };

  const checkNextButton = () => {
    if (
      (!haveValue(user?.have_gurantor) ||
        !user?.have_gurantor ||
        user?.have_gurantor == "false") &&
      (!haveValue(user?.kyc_success) ||
        !user?.kyc_success ||
        user?.kyc_success == "false")
    ) {
      return (
        <button
          className="btn btnorange step-btn"
          onClick={(e) => navigate("/tenant/connectBank")}
        >
          {_t("Continue To Connect Bank")}
        </button>
      );
    } else if (
      !skipMaccUser(user?.user_type) &&
      (!haveValue(user?.docs_uploaded) || !user?.docs_uploaded)
    ) {
      return (
        <button
          onClick={(e) => navigate("/tenant/document")}
          className="btn btnorange step-btn"
        >
          {_t("Continue to Provide Document")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div id="onfido-mount"></div>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={openBackModal}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              <h3 class="step-form-name">
                <img src="/images/verify-home-icon.svg" alt="" />
                <span>{_t("Verify ID")}</span>
              </h3>
            </div>
            <div className="steps-wrapper">
              <h3 className="step-name">
                {_t("Use original document for verification")}
              </h3>
              <p className="step-sub-head">
                {_t("We recommend to use Spanish ID, if available")}
              </p>
              <div className="icon-text-box">
                <img src="/images/doc-icon.svg" alt="" />
                <div className="text-box">
                  <h5 className="box-heading">
                    {_t("Get your original document")}
                  </h5>
                  <p className="box-desc">
                    {_t("You need to have the physical ID with you")}
                  </p>
                </div>
              </div>
              <div className="icon-text-box">
                <img src="/images/world-icon.svg" alt="" />
                <div className="text-box">
                  <h5 className="box-heading">
                    {_t("Select the country and type of document")}
                  </h5>
                  <p className="box-desc">
                    {_t(
                      "Make sure you pick the appropriate country according to your document"
                    )}
                  </p>
                </div>
              </div>
              <div className="icon-text-box">
                <img src="/images/camera-icon.svg" alt="" />
                <div className="text-box">
                  <h5 className="box-heading">
                    {_t("Take a picture and verify it")}
                  </h5>
                  <p className="box-desc">
                    {_t(
                      "Get into your phone to take a picture of your original document and get it verified."
                    )}
                  </p>
                </div>
              </div>
              <p className="note-text">
                {_t(
                  "Note: photocopies or other existing pictures are not allowed."
                )}
              </p>
              <button
                className="btn btnorange step-btn"
                onClick={initiateOnfido}
                disabled={buttonDisable}
              >
                {_t("Verify ID")}
              </button>
            </div>
          </div>
          <Modal
            className="modal-uploadpdf"
            show={openModal}
            onHide={(e) => setOpenModal(false)}
            centered={window.screen.width <= 767}
          >
            <Modal.Header closeButton>
              <h5 className="modal-main-heading">
                {_t("Why Do You Want to Leave?")}
              </h5>
            </Modal.Header>
            <Modal.Body>
              <p className="modal-sub-heading">{_t("Please Choose One")}</p>
              <button
                className="pdf-btn"
                onClick={(e) =>
                  handleGobackReason(
                    "I don't have the original document with me"
                  )
                }
              >
                {_t("I don't have the original document with me")}
              </button>
              <button
                className="pdf-btn"
                onClick={(e) =>
                  handleGobackReason(
                    "I am unable to capture the image of the document"
                  )
                }
              >
                {_t("I am unable to capture the image of the document")}
              </button>
              <button
                className="pdf-btn"
                onClick={(e) => handleGobackReason("I am getting an error")}
              >
                {_t("I am getting an error")}
              </button>
            </Modal.Body>
          </Modal>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="congratulation-screen steps-wrapper">
              <div className="step-form-header">
                <h3 class="step-form-name">
                  <img src="/images/verify-home-icon.svg" alt="" />
                  <span>{_t("Verify ID")}</span>
                </h3>
              </div>
              <img
                className="congrats-image"
                src="/images/confetti.svg"
                alt=""
              />
              <h3 className="congrats-text">{_t("Congratulations")}</h3>
              <p className="congrats-desc">
                {_t(
                  "Your identification verification is in progress. Once verified you will be rewarded with 30 points"
                )}
              </p>
              {checkNextButton()}
              <button className="btn btnoutline" onClick={navigateBackHome}>
                {_t("Go Back home")}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantVerifyId;
