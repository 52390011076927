import { useEffect, useState } from "react";
import GaugeComponent from "react-gauge-component";

function ProfileScore({ creditScore, rentScore, showRental = true, children }) {
  const [rFlag, setRFlag] = useState(0);
  const creditScoreVal = !isNaN(creditScore) ? creditScore / 10 : 0;
  const rentScoreVal = !isNaN(rentScore) ? rentScore / 10 : 0;
  const totalScoreVal = creditScoreVal + rentScoreVal;

  const BG_GRADIENT = (
    <svg width="0" height="0">
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="28.33%"
            style={{ stopColor: "#F5DD01", stopOpacity: 1 }}
          />
          <stop
            offset="70.82%"
            style={{ stopColor: "#99DE04", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
    </svg>
  );

  const BG_COLOR = "#EEEEEE";
  const TRANSPARENT_COLOR = "#00000000";

  const creditScoreColors = [
    "#F3483E",
    "#F77841",
    "url(#grad1)",
    "#99DE04",
    "#05B801",
  ];
  const rentScoreColors = [
    "#8D7DDA",
    "#8D7DDA",
    "#8D7DDA",
    "#8D7DDA",
    "#8D7DDA",
  ];

  //credit scores

  let creditArcColors = [];
  let creditSubArc = [];

  if (creditScoreVal > 0 && creditScoreVal <= 20) {
    creditArcColors = [
      creditScoreColors[0],
      TRANSPARENT_COLOR,
      TRANSPARENT_COLOR,
      TRANSPARENT_COLOR,
      TRANSPARENT_COLOR,
    ];
    creditSubArc = [
      { limit: creditScoreVal },
      { limit: 40 },
      { limit: 60 },
      { limit: 80 },
      { limit: 100 },
    ];
  } else if (creditScoreVal > 20 && creditScoreVal <= 40) {
    creditArcColors = [
      creditScoreColors[0],
      creditScoreColors[1],
      TRANSPARENT_COLOR,
      TRANSPARENT_COLOR,
      TRANSPARENT_COLOR,
    ];
    creditSubArc = [
      { limit: 20 },
      { limit: creditScoreVal },
      { limit: 60 },
      { limit: 80 },
      { limit: 100 },
    ];
  } else if (creditScoreVal > 40 && creditScoreVal <= 60) {
    creditArcColors = [
      creditScoreColors[0],
      creditScoreColors[1],
      creditScoreColors[2],
      TRANSPARENT_COLOR,
      TRANSPARENT_COLOR,
    ];
    creditSubArc = [
      { limit: 20 },
      { limit: 40 },
      { limit: creditScoreVal },
      { limit: 80 },
      { limit: 100 },
    ];
  } else if (creditScoreVal > 60 && creditScoreVal <= 80) {
    creditArcColors = [
      creditScoreColors[0],
      creditScoreColors[1],
      creditScoreColors[2],
      creditScoreColors[3],
      TRANSPARENT_COLOR,
    ];
    creditSubArc = [
      { limit: 20 },
      { limit: 40 },
      { limit: 60 },
      { limit: creditScoreVal },
      { limit: 100 },
    ];
  } else if (creditScoreVal > 80 && creditScoreVal <= 100) {
    creditArcColors = [
      creditScoreColors[0],
      creditScoreColors[1],
      creditScoreColors[2],
      creditScoreColors[3],
      creditScoreColors[4],
    ];
    creditSubArc = [
      { limit: 20 },
      { limit: 40 },
      { limit: 60 },
      { limit: 80 },
      { limit: creditScoreVal },
    ];
    if (creditScoreVal < 100) {
      creditArcColors.push(TRANSPARENT_COLOR);
      creditSubArc.push({ limit: 100 });
    }
  }

  //rent score

  let rentArcColors = [];
  let rentSubArc = [];

  if (totalScoreVal > creditScoreVal) {
    if (totalScoreVal > 0 && totalScoreVal <= 20) {
      rentArcColors = [
        TRANSPARENT_COLOR,
        rentScoreColors[0],
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
      ];
      rentSubArc = [
        { limit: creditScoreVal },
        { limit: totalScoreVal },
        { limit: 40 },
        { limit: 60 },
        { limit: 80 },
        { limit: 100 },
      ];
    } else if (totalScoreVal > 20 && totalScoreVal <= 40) {
      rentArcColors = [
        TRANSPARENT_COLOR,
        rentScoreColors[1],
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
      ];
      rentSubArc = [
        { limit: creditScoreVal },
        { limit: totalScoreVal },
        { limit: 60 },
        { limit: 80 },
        { limit: 100 },
      ];
    } else if (totalScoreVal > 40 && totalScoreVal <= 60) {
      rentArcColors = [
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        rentScoreColors[2],
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
      ];
      rentSubArc = [
        { limit: 20 },
        { limit: creditScoreVal },
        { limit: totalScoreVal },
        { limit: 80 },
        { limit: 100 },
      ];
    } else if (totalScoreVal > 60 && totalScoreVal <= 80) {
      rentArcColors = [
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        rentScoreColors[3],
        TRANSPARENT_COLOR,
      ];
      rentSubArc = [
        { limit: 20 },
        { limit: 40 },
        { limit: creditScoreVal },
        { limit: totalScoreVal },
        { limit: 100 },
      ];
    } else if (totalScoreVal > 80 && totalScoreVal <= 100) {
      rentArcColors = [
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        TRANSPARENT_COLOR,
        rentScoreColors[4],
      ];
      rentSubArc = [
        { limit: 20 },
        { limit: 40 },
        { limit: 60 },
        { limit: creditScoreVal },
        { limit: totalScoreVal },
      ];
      if (totalScoreVal < 100) {
        rentArcColors.push(TRANSPARENT_COLOR);
        rentSubArc.push({ limit: 100 });
      }
    }
  }

  const baseArcOption = {
    cornerRadius: 5,
    colorArray: [BG_COLOR, BG_COLOR, BG_COLOR, BG_COLOR, BG_COLOR],
    subArcs: [
      { limit: 20 },
      { limit: 40 },
      { limit: 60 },
      { limit: 80 },
      { limit: 100 },
    ],
    padding: 0.02,
    width: 0.15,
  };

  const baseGaugeOptions = {
    type: "radial",
    labels: {
      valueLabel: {
        hide: true,
      },
      tickLabels: {
        hideMinMax: true,
      },
    },
    arc: baseArcOption,
    pointer: {
      length: 0,
      // type: "arrow",
      // elastic: false,
      // animationDelay: 0,
    },
  };
  if (!showRental) {
    baseGaugeOptions.pointer = {
      length: 0.8,
      type: "arrow",
      elastic: false,
      animationDelay: 0,
    };
  }
  const baseGaugeOptions1 = {
    type: "radial",
    labels: {
      valueLabel: {
        hide: true,
      },
      tickLabels: {
        hideMinMax: true,
      },
    },
    arc: baseArcOption,
    pointer: {
      // type: "needle",
      type: "arrow",
      elastic: false,
      length: 0.8,
      animationDelay: 0,
    },
  };

  setTimeout(() => {
    // Create a new resize event
    const resizeEvent = new Event("resize");

    // Dispatch the event on the window object
    window.dispatchEvent(resizeEvent);
  }, 100);
  // useEffect(() => {
  //   console.log("gague innernnnnn");
  // }, []);

  return (
    <>
      {/* <h4 style={{ textAlign: "center" }}>
        Credit Score: {creditScore}, Rent Score: {rentScore}
      </h4>
      <p style={{ textAlign: "center" }}>
        Credit Score Val: {creditScoreVal}, Rent Score Val: {rentScoreVal},
        Total Score Val: {totalScoreVal}
      </p> */}
      {BG_GRADIENT}
      <div className="guage-box">
        <div className="guage-main">
          <div className="guage-main-item guage-main-lower">
            <GaugeComponent value={creditScoreVal} {...baseGaugeOptions} />
          </div>

          <div
            className="guage-main-item guage-main-upper"
            style={{ position: "relative" }}
          >
            <GaugeComponent
              {...baseGaugeOptions}
              value={creditScoreVal}
              arc={{
                ...baseArcOption,
                colorArray: creditArcColors,
                subArcs: creditSubArc,
              }}
            />
            <div className="skor_limits">
              <p>0</p>
              <p>1000</p>
            </div>
          </div>
        </div>

        {showRental && (
          <>
            <div className="guage-main-inner">
              <div className="guage-main-inner-item guage-main-inner-lower">
                <GaugeComponent value={totalScoreVal} {...baseGaugeOptions1} />
              </div>

              <div className="guage-main-inner-item guage-main-inner-upper">
                <GaugeComponent
                  {...baseGaugeOptions1}
                  value={totalScoreVal}
                  arc={{
                    ...baseArcOption,
                    colorArray: rentArcColors,
                    subArcs: rentSubArc,
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="gague-val-box">
          <div className="gague-val">{children}</div>
        </div>
      </div>
    </>
  );
}

export default ProfileScore;
