import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link as link1, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Loader from "../../components/Loader";
import Spinner from "../../components/Spinner";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { usePlaidLink } from "react-plaid-link";

// import  Verification from '../../assets/img/verification-icon'
import Verification from "../../assets/img/verification-icon.png";
import Bank from "../../assets/img/bank-icon.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TenantContext from "../../context/tenant/TenantContext";
import Error from "../../components/Error";

import uploadicon from "../../assets/img/upload.png";
import successicon from "../../assets/img/successgif.gif";
import failedicon from "../../assets/img/cross.png";

import AccountLoading from "../../components/AccountLoading";
import { BsBank } from "react-icons/bs";
import { ImProfile } from "react-icons/im";
import { BsUpload } from "react-icons/bs";
import { sendUniversalMail } from "../../services/sharedService";
import useTranslator from "../../hooks/useTranslator";
import { Modal } from "react-bootstrap";
import { init, setOptions } from "onfido-sdk-ui";
// import { Onboarding, OnboardingConfig, DocumentType, DocumentStageConfig, DocumentCapturerType, SelfieStageConfig } from "aliceonboarding";
// import "aliceonboarding/dist/aliceonboarding.css";

const Kyc = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const formData = new FormData();
  const authContext = useContext(AuthContext);
  const { loading, changeImage, user, loadUser, isLoggedIn } = authContext;
  const tenantContext = useContext(TenantContext);
  const {
    setLoading,
    saveKycInfo,
    aliceKyc,
    onfidoKyc,
    isError,
    isLoading,
    getKycInfo,
    kyc_done,
    getPlaidLinkToken,
  } = tenantContext;

  const [linkToken, setLinkToken] = useState(null);
  const [bankLoading, setBankLoading] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [onfidoKycStatus, setOnfidoKycStatus] = useState("pending");
  const [aliceKycStatus, setAliceKycStatus] = useState("not started");
  const [docType, setDocType] = useState("");
  const [showAliceModal, setShowAliceModal] = useState(false);
  const [initiateData, setInitiateData] = useState();
  const [buttonDisable, setButtonDisable] = useState(false);

  const [countryCodeError, setCountryCodeError] = useState("");

  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);
  console.log("HERE WERE queryParameters", queryParameters);
  const error = queryParameters.get("error");
  const error_message = queryParameters.get("error_reason");
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");
  // Onboarding.setEnvironment("sandbox")

  useEffect(() => {
    console.log("kyc page -----", account_verification_report_id);
    let token = localStorage.getItem("token");
    let usertype = localStorage.getItem("usertype");
    console.log("TOKEN = ", token);
    console.log("User TYPE = ", usertype);
    if (token && usertype) {
      if (usertype !== "tenant") navigate("/home");
      loadUser(usertype);
      if (user?._id) setKycForm(user);
    } else {
      navigate("/login");
    }

    if (error) {
      toast.error(_t("Bank Details cannot be fetched!"));
    }
    if (account_verification_report_id && transaction_report_id) {
      snackbar.showMessage(_t("Connected to Bank"));
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("User Not Authorized"));
      } else {
        //if we have both the ids then what to do
        //  toast.success(account_verification_report_id);
        //  toast.success(transaction_report_id);
        //  toast.success(userid);
        console.log("Calculate Score============>");
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
      //when we will configure bank details from backend
    }
  }, [account_verification_report_id]);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    console.log("payment score calculations");
    setBankDiv("kyc-loading");
    await clearPaymentData(userid);
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
  };

  const clearPaymentData = async (tenantid) => {
    console.log("Clearing Payment Data ===========>> ");
    return new Promise((resolve) => {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          console.log(res);
          //toast.success(res.data.message);
          //toast.success("Previous Data cleared");
          console.log("previous data cleared");
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        console.log(serverResponse);
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
            setCrediterr(serverResponse);
            setBankDiv("algoan-pending");
            console.log("in error state", crediterr);
          } else {
            toast.error(_t("Skor can not be calculated"));
            setCrediterr(serverResponse);
            setBankDiv("kyc-error");
            console.log("in error state", crediterr);
          }
        }
        if (serverResponse.status === "success") {
          //toast.success("Credit score has been calculated")
          setBankDiv("kyc-loaded");
          // navigate('/home');
        }
      })
      .catch((err) => {
        let err_res = err;
        console.log(err_res);

        //toast.error(err_res.message);
        //navigate('/home');
      });
  };

  const [bankDiv, setBankDiv] = useState("kyc-form");
  const [kycForm, setKycForm] = useState({
    have_gurantor: "no",
    gurantor_name: "",
    gurantor_email: "",
    gurantor_relation: "",
    have_sharer: "no",
    sharer_name: "",
    sharer_email: "",
  });
  const [crediterr, setCrediterr] = useState({});

  const onChange = (e) => {
    //console.log(e.target.value);
    setKycForm({ ...kycForm, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("KYC FORM = ", kycForm);
    // return;
    //uploadImage(e);
    saveKycInfo(user?._id, kycForm);
    if (kycForm.gurantor_email != "") {
      sendMail(kycForm?.gurantor_email, "score");
    }
    if (kycForm.sharer_email != "") {
      sendMail(kycForm?.sharer_email, "sharer");
    }

    navigate("/home");
  };
  //image uploading
  const [file, setFile] = useState({});

  const handleImage = (e) => {
    //setFile(e.target.files[0]);
    console.log(e.target.files[0]);
    //toast.success("Image loaded")
    //submitting image on onChange function
    formData.append("file", e.target.files[0]);
    console.log(formData);
    changeImage(formData, user?._id);
  };

  const uploadImage = (e) => {
    e.preventDefault();
    console.log(file);
    formData.append("file", file);
    console.log(formData);
    changeImage(formData, user?._id);
  };

  let tink_countryCode = "";
  const changeCountryCode = (e) => {
    tink_countryCode = e.target.value;
    console.log(tink_countryCode);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError("*Please select country code");
      return;
    } else {
      setCountryCodeError("");
    }

    // let tinkUrl = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    // let tinkUrl = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2Fapp.skortorent.com%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    // let tinkUrlFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2Fapp.skortorent.com%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    // let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2Fapp.skortorent.com%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    // let tinkUrlLiveApp = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_LIVE_CLIENT_ID}&redirect_uri=https%3A%2F%2Fapp.skortorent.com%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    // let tinkUrlLiveApp = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_LIVE_CLIENT_ID}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fkyc&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;

    window.open(tinkUrlLiveFront, "_self");
    // window.open(tinkUrl, "_self");

    // window.open(tinkUrlFront, "_self");
    //window.open(tinkUrlLive, "_self");
    //window.open(tinkUrlLiveFront, "_self");
    // let tinkUrlLiveApp = `https://link.tink.com/1.0/transactions/connect-accounts/?client_id=fe7ea1f410c1460ea930d538617d54e3&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fkyc&market=SE&locale=en_US`
    // window.open(tinkUrlLiveApp, "_self");
  };

  //kyc flow

  const initiateOnfido = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        console.log("ONFIDO WITH WORKFLOW= ", res.data);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        console.log("Error Onfido = ", err);
        setOnfidoKycStatus("server-error");
      });
  };

  const initiateKyc = (token, workFlowRunId) => {
    console.log("On Fido Token = ", token);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      language: localStorage.getItem("i18nextLng") == "es" ? "es" : "en_US",
      containerId: "onfido-mount",
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        console.log("everything is complete");
        console.log("onfido success response", data);
        saveOnfidoKycRespone(data);
      },
      onError: function (err) {
        console.log("onfido error happend");
        console.log("ERROR OnFIDO = ", err);
      },
      onUserExit: function (userExitCode) {
        console.log("user exitted", userExitCode);
      },
      onModalRequestClose: function () {
        // Update options with the state of the modal
        onfido.setOptions({ isModalOpen: false });
        console.log("modal closed of onfido");
      },
      //steps: ['welcome', 'poa', 'document', 'face', 'complete'],  //poa-proof of address
      steps: ["welcome", "document", "face", "complete"],
    });
  };

  const saveOnfidoKycRespone = (kycData) => {
    onfidoKyc(user?._id, kycData)
      .then((res) => {
        console.log("promise resolved then response", res);
        setOnfidoKycStatus("under_process");
      })
      .catch((err) => {
        console.log("promise rejected err is ", err);
        setOnfidoKycStatus("failed");
      });
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("Email Sent"));
        console.log(res);
      })
      .catch((err) => {
        snackbar.showMessage(_t("Error Sending Mail"), err?.message);
        console.log(err);
      });
  };

  const generateToken = async () => {
    try {
      const response = await getPlaidLinkToken();
      console.log(response);
      const data = await response?.success_res?.sendData;
      setLinkToken(data.link_token);
    } catch (err) {
      console.log(err);
    }
  };

  const initiateInespay = async () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/tenants/inititateInespay`, {
        userId: user?._id,
      })
      .then((res) => {
        window.location.replace(res.data.success_res.singlePayinLink);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <button onClick={initiateInespay}>Inespay</button>

      {linkToken != null && <Link linkToken={linkToken} />}
      {loadingLocal ? (
        <>
          <Spinner />
        </>
      ) : (
        <div className="main-wapper height-auto bg-main-white">
          <div className="container d-block">
            <div className="row">
              <div className="title-kyc">
                <h2>{_t("KYC")}</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="flex-wapper flex-wapper2">
                  <div className="left-upload-wapper">
                    <div className="width-section col100-left">
                      {onfidoKycStatus === "pending" && (
                        <>
                          <div>
                            <label>
                              {_t("Verify Your Identity(DNI,NIE,Passport)")}
                            </label>
                            <div id="onfido-mount"></div>
                            <button
                              className="btn-bro connect-bank"
                              onClick={initiateOnfido}
                              disabled={buttonDisable}
                            >
                              {" "}
                              <ImProfile /> {_t("Digital Verification")}
                            </button>
                          </div>
                        </>
                      )}

                      {onfidoKycStatus === "under_process" && (
                        <>
                          <div>
                            <div className="tabs-item d-block">
                              <h4>
                                {_t("Kyc Under Process")}{" "}
                                <img
                                  src={successicon}
                                  width={40}
                                  height={40}
                                  alt=""
                                />{" "}
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                      {onfidoKycStatus === "server-error" && (
                        <>
                          <div>
                            <div className="tabs-item d-block">
                              <h4>
                                {_t("No Response From Server")}
                                <img
                                  src={failedicon}
                                  width={40}
                                  height={40}
                                  alt=""
                                />{" "}
                              </h4>
                              <button
                                onClick={() => setOnfidoKycStatus("pending")}
                                className="btn-fill2"
                              >
                                {_t("Try Again")}!{" "}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {onfidoKycStatus === "failed" && (
                        <>
                          <div>
                            <div className="tabs-item d-block">
                              <h4>
                                {_t("Your identity verification failed")}{" "}
                                <img
                                  src={failedicon}
                                  width={30}
                                  height={30}
                                  alt=""
                                />{" "}
                              </h4>
                              <button
                                onClick={() => setOnfidoKycStatus("pending")}
                                className="btn-fill2"
                              >
                                {_t("Try Again")}!{" "}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="left-upload-wapper left-upload-wapper2">
                    <div className="width-section col100-left">
                      {bankDiv == "kyc-form" && (
                        <div className="kyc-info">
                          {/* <button classNameName='btn btn-warning' onClick={connectToBank}>Connect Your Bank</button> */}
                          <button
                            className="btn-bro connect-bank"
                            onClick={generateToken}
                          >
                            {" "}
                            <BsBank className="img-bank" /> {_t("Connect Bank")}
                          </button>
                        </div>
                      )}
                      {bankDiv == "kyc-loading" && (
                        <>
                          <AccountLoading />
                        </>
                      )}
                      {bankDiv == "kyc-loaded" && (
                        <div>
                          <div className="tabs-item d-block">
                            <h4>
                              {_t("Bank has been connected")}{" "}
                              <img
                                src={successicon}
                                width={40}
                                height={40}
                                alt=""
                              />{" "}
                            </h4>
                          </div>
                        </div>
                      )}
                      {bankDiv == "kyc-error" && (
                        <>
                          <div className="tabs-item d-block">
                            <p>{_t("Error Message")}:</p>

                            <h4>{crediterr?.errormsg?.message}</h4>
                            <h4>{"Algoan Error"}</h4>
                            <h4>{crediterr?.msg}</h4>
                            <button
                              onClick={() => setBankDiv("kyc-form")}
                              className="btn-fill2"
                            >
                              {_t("Try Again")}!{" "}
                            </button>
                          </div>
                        </>
                      )}
                      {bankDiv == "algoan-pending" && (
                        <>
                          {/* <div className="tabs-item d-block">
                       <p></p>
                   
                        <h4>{""}</h4>
                        <h4>{crediterr?.msg}</h4>
                        <h6>It will be automatically displayed on HomePage when completed</h6>
                        </div> */}

                          <div>
                            <div className="tabs-item d-block">
                              <h4>
                                {_t("Bank has been connected")}{" "}
                                <img
                                  src={successicon}
                                  width={40}
                                  height={40}
                                  alt=""
                                />{" "}
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={onSubmit} encType="multipart/form-data">
                <div className="row">
                  <div className="col-lg-10 mx-auto">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="info-wapper">
                          <label>{_t("Do you have a Guarantor")}?</label>
                          <div className="select-radio mb-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="have_gurantor"
                                value="yes"
                                onChange={onChange}
                                checked={
                                  kycForm?.have_gurantor == "yes" ? true : false
                                }
                              />
                              <label className="form-check-label">
                                {_t("Yes")}
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="have_gurantor"
                                value="no"
                                onChange={onChange}
                                checked={
                                  kycForm?.have_gurantor == "no" ? true : false
                                }
                              />
                              <label className="form-check-label">
                                {_t("No")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="info-wapper">
                          <div
                            className={
                              kycForm?.have_gurantor == "yes"
                                ? "normal"
                                : "disable-add"
                            }
                          >
                            <label>{_t("Name Of Guarantor")}</label>
                            <input
                              type="text"
                              className="custom-input"
                              name="gurantor_name"
                              placeholder="John"
                              onChange={onChange}
                              value={kycForm?.gurantor_name || ""}
                            />

                            <label>{_t("Email Of Guarantor")}</label>
                            <input
                              type="email"
                              className="custom-input"
                              name="gurantor_email"
                              placeholder="demo@xyz"
                              onChange={onChange}
                              value={kycForm?.gurantor_email || ""}
                            />

                            <label>{_t("Guarantor Relation")}</label>
                            <input
                              type="text"
                              className="custom-input"
                              name="gurantor_relation"
                              placeholder="Friend"
                              onChange={onChange}
                              value={kycForm?.gurantor_relation || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12">
                        <div className="info-wapper">
                          <label>{_t("Do you have a Sharer?")}</label>
                          <div className="mb-4">
                            <div className="select-radio ">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="have_sharer"
                                  value="yes"
                                  onChange={onChange}
                                  checked={
                                    kycForm?.have_sharer == "yes" ? true : false
                                  }
                                />
                                <label className="form-check-label">
                                  {_t("Yes")}
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="have_sharer"
                                  value="no"
                                  onChange={onChange}
                                  checked={
                                    kycForm?.have_sharer == "no" ? true : false
                                  }
                                />
                                <label className="form-check-label">
                                  {_t("No")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="info-wapper">
                          <div
                            className={
                              kycForm?.have_sharer == "yes"
                                ? "normal"
                                : "disable-add"
                            }
                          >
                            <label className="label">
                              {_t("Name Of Sharer")}
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="sharer_name"
                              placeholder="John"
                              onChange={onChange}
                              value={kycForm?.sharer_name || ""}
                            />
                            <label className="label">
                              {_t("Email Of Sharer")}
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="sharer_email"
                              placeholder="demo@xyz"
                              onChange={onChange}
                              value={kycForm?.sharer_email || ""}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12"></div>

                      <div className="col-md-6 col-12"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="row">
              <div>
                <button className="btn-fill2" onClick={onSubmit}>
                  {_t("Submit")}
                </button>
                <button
                  className="btn-fill-border btn-fill-border-orange"
                  onClick={() => navigate("/home")}
                >
                  {_t("Back")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Link = (props) => {
  const tenantContext = useContext(TenantContext);
  const { sendPublicToken } = tenantContext;
  const onSuccess = React.useCallback((public_token, metadata) => {
    const response = sendPublicToken(public_token);
    // Handle response ...
  }, []);
  const config = {
    token: props.linkToken,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    open();
  });
};

export default Kyc;
