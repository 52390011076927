import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/auth/AuthContext";
import LandLordContext from "../context/landlord/LandLordContext";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import { FaArrowLeft } from "react-icons/fa6";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import '../assets/css/auth.css'
import Loader from "../components/Loader";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from "react-icons/bs";
import useTranslator from "../hooks/useTranslator";
import { useCookies } from "react-cookie";
import TenantContext from "../context/tenant/TenantContext";
import { haveValue, getBaseUrl } from "../helper/common";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";
import i18next from "i18next";
import constants from "../Utils/constants";
import OTPInput, { ResendOTP } from "otp-input-react";

let timer = 60;
let myInterval = null;

const Login = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const { hash } = useLocation();
  const snackbar = useSnackbar();

  const user_type = localStorage.getItem("usertype");
  const authContext = useContext(AuthContext);
  const {
    toggleLogin,
    loading,
    setLoading,
    loadUser,
    setUserType,
    verified,
    forgotPassword,
    sendOTPES,
    sendOTP
  } = authContext;

  const landLordContext = useContext(LandLordContext);
  const { loadingLandlord, demoFunLandlord, setProperties, properties } =
    landLordContext;

  const tenantContext = useContext(TenantContext);
  const { shareProfile, linkGurantor } = tenantContext;

  const queryParameters = new URLSearchParams(window.location.search);
  const logincode = queryParameters.get("code");

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng").toLowerCase() || "en-us"
  );

  const [authData, setAuthData] = useState(null);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [showTimer, setShowTimer] = useState(timer);


  const handleLanguageChange = async (e) => {
    console.log(e.target.value);
    var langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    console.log("Lng should be changed by now");
    setCurrentLang(langVal);
  };

  useEffect(() => {
    if (hash === "#tenant") {
      setOtp((prev) => ({ ...prev, type: "tenant" }));
    } else if (hash === "#landlord") {
      setOtp((prev) => ({ ...prev, type: "landlord" }));
    }
  }, [hash]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let is_verified = localStorage.getItem("is_verified");

    if (token && is_verified) {
      toggleLogin(true);
      console.log("user is logged in");
      loadUser(user_type);
      if (user_type == "tenant") {
        navigate("/tenant");
      } else {
        navigate("/home");
      }
    }
  }, [toggleLogin, navigate]);

  useEffect(() => {
    if (logincode) {
      setLoading(true);
      console.log(logincode);
      let user_type = localStorage.getItem("usertype");
      if (!user_type) {
        toast.error(_t("please select your user type"));
      } else {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_URL
            }/users/linkedin-login/${logincode}?usertype=${user_type}&user_type=${window.location.hostname == "alquilatuhogar.skortorent.com"
              ? constants.MACC
              : constants.SKOR
            }`,
            {
              headers: {
                "Accept-Language": localStorage.getItem("i18nextLng"),
              },
            }
          )
          .then(async (res) => {
            let serverResponse = res;
            console.log(res.data);
            localStorage.setItem("token", serverResponse.data.token);
            localStorage.setItem("is_verified", true);
            toggleLogin(true);
            await loadUser(user_type);
            setLoading(false);
            if (
              haveValue(cookies.sharedProfileLink) &&
              user_type == "landlord"
            ) {
              if (haveValue(res?.data?.user?._id)) {
                shareProfile(
                  cookies.sharedProfileLink,
                  res?.data?.user?._id,
                  "null",
                  "full",
                  { shareType: "tLink" }
                )
                  .then((res) => {
                    removeCookie("sharedProfileLink", { maxAge: 1800 });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
            if (
              haveValue(cookies.requestProfileLink) &&
              user_type == "tenant"
            ) {
              if (haveValue(res?.data?.user?._id)) {
                if (res?.data?.user?.docSigned) {
                  shareProfile(
                    res?.data?.user?._id,
                    cookies.requestProfileLink,
                    "null",
                    "full",
                    { shareType: "lLink" }
                  )
                    .then((res) => {
                      removeCookie("requestProfileLink", { maxAge: 1800 });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  navigate("/profile-shared?agreement=true");
                }
              }
            }
            if (serverResponse.data.user.has_profile) {
              if (
                serverResponse.data.user.role == "tenant" &&
                !serverResponse.data.user.kyc_success
              ) {
                // navigate("/home");
                navigate("/tenant");
              } else {
                navigate("/home");
                // navigate("/tenant");
                //setting properties in landlord state
                console.log("profile exist of landlord");
              }
            } else {
              if (localStorage.getItem("usertype") == "tenant") {
                navigate("/tenant");
              } else {
                navigate("/home");
              }
              console.log("profile  NOT exist");
            }
            //navigate('/home');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            navigate("/login");
            //toast.error(err.data.message);
            toast.error(_t("Please sign up first using linkedin"));
          });
      }
    }
  }, [logincode]);

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
    type: "",
    checkedTenant: false,
    checkedLandlord: false,
    checked: false,
    user_type:
      window.location.hostname == "alquilatuhogar.skortorent.com"
        ? constants.MACC
        : constants.SKOR,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otp, setOtp] = useState({
    type: "",
    otp: 0,
  });
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    if (hash === "#tenant") {
      setShowForgotPassword(false);
      setUserLogin((state) => ({
        ...state,
        type: "tenant",
        checkedTenant: true,
      }));
    } else if (hash === "#landlord") {
      setShowForgotPassword(false);
      setUserLogin((state) => ({
        ...state,
        type: "landlord",
        checkedLandlord: true,
      }));
    } else {
      navigate("/userrole");
    }
  }, []);

  const { checked, checkedLandlord, checkedTenant, user } = userLogin;

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error(_t("Invalid email address"));
    return false;
  };

  const onChange = (e) => {
    if (e.target.name == "email")
      setUserLogin({
        ...userLogin,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    else setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };
  const handleCheck = (e) => {
    if (checked === false) {
      setUserLogin({ ...userLogin, checked: true });
    } else {
      setUserLogin({ ...userLogin, checked: false });
    }
  };

  const handleUsertype = (e) => {
    setUserType(e.target.value);
    if (e.target.value === "landlord") {
      setUserLogin({
        ...userLogin,
        checkedLandlord: true,
        checkedTenant: false,
        type: "landlord",
      });
    }
    if (e.target.value === "tenant") {
      setUserLogin({
        ...userLogin,
        checkedLandlord: false,
        checkedTenant: true,
        type: "tenant",
      });
    }
  };

  const redirectFun = (data) => {
    //LogIn Message
    snackbar.showMessage(_t("Logged In Successfully"));

    const token = data.token;
    const is_verified = true;
    const usertype = localStorage.getItem('usertype');
    const saveduserid = data.user._id;
    const external_customer_id = data.user._id;
    const user = data.user;

    if (window.location.host === process.env.REACT_APP_MACC_URL_NAME) {
      if (token && is_verified) {
        const app_type = "macc";
        // Create a query string from the constants
        const queryParams = new URLSearchParams({
          token: token || '',                      // Append token
          usertype: usertype || '',                // Append usertype
          is_verified: is_verified || '',          // Append is_verified
          saveduserid: saveduserid || '',          // Append saveduserid
          external_customer_id: external_customer_id || '', // Append external_customer_id
          app_type: app_type || ''                           // // Append app_type
        });
        // Redirect to the new URL with query parameters
        document.body.innerHTML = '';
        window.location.replace(`${window.location.protocol}//${process.env.REACT_APP_URL_NAME}/redirect?${queryParams.toString()}`);
        return false;
      }
    }

    //share profile
    if (haveValue(cookies.sharedProfileLink) && userLogin.type === "landlord") {
      if (haveValue(data?.user?._id)) {
        shareProfile(
          cookies.sharedProfileLink,
          data?.user?._id,
          "null",
          "full",
          { shareType: "tLink" }
        )
          .then((res) => {
            removeCookie("sharedProfileLink", { maxAge: 1800 });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    toggleLogin(true);

    //Set Local Storage
    localStorage.setItem("token", token);
    localStorage.setItem("is_verified", true);
    localStorage.setItem("saveduserid", saveduserid);
    localStorage.setItem("external_customer_id", external_customer_id);

    loadUser(userLogin.type);

    //Redirecting
    if (!user.has_profile) {
      navigate(usertype === "tenant" ? "/tenant" : "/home");
    } else if (user.role === "tenant" && !user.kyc_success) {
      navigate("/tenant");
    } else {
      navigate(usertype === "tenant" ? "/tenant" : "/home");
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (userLogin.email === "" || userLogin.password === "") {
      toast.error(_t("Please Input Your Credentials"));
    } else {
      if (validateEmail(userLogin.email)) {
        setLoading(true);

        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/users/login`, userLogin, {
            headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
          })
          .then((res) => {
            setLoading(false);
            let data = res.data;
            if (data.token) {
              //Verified
              if (data.user.verified) {
                redirectFun(data);
              }
              else {
                //send OTP
                const usertype = localStorage.getItem('usertype');
                if (localStorage.getItem("i18nextLng") === 'es')
                  sendOTPES(data.user._id, usertype);
                else {
                  sendOTP(data.user._id, usertype);
                }

                setAuthData(data);
                //show otp screen
                setShowOtpScreen(true)
              }
            }
            else {
              setLoading(false);
              toast.error(_t("Login Failed"));
            }
          })
          .catch((err) => {
            setLoading(false);
            const message = err.response?.data?.message || err.response?.data?.msg || err.message;
            toast.error(message);
            navigate("/login");
          });
      }
    }
  };

  const loginLinkedin = () => {
    const baseUrl = getBaseUrl();
    // let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=http://localhost:3000/login`;
    let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=https://${process.env.REACT_APP_URL_NAME}/login`;

    let user_type = localStorage.getItem("usertype");
    if (!user_type) {
      toast.error(_t("Please specify user type first"));
    } else {
      // window.open(url, "_self");
      window.open(live_url, "_self");
    }
  };

  const handleRedirectToLandingPage = () => {
    window.location.replace("https://skortorent.com/");
  };

  const handleShowPassword = (val) => {
    if (val) {
      setPasswordType("text");
      setShowPassword((oldVal) => !oldVal);
    } else {
      setPasswordType("password");
      setShowPassword((oldVal) => !oldVal);
    }
  };

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
    setShowForgotPassword(true);
  };

  const onSubmitForgotPassword = async (e) => {
    e.preventDefault();
    if (userLogin.email === "") {
      snackbar.showMessage(_t("Please Enter Your Email"));
    } else {
      const data = {
        email: userLogin?.email,
        type: localStorage.getItem("usertype"),
      };
      const res = await forgotPassword(data);
      console.log("formsubmitted");
    }
  };


  const otpinputChange = (e) => {
    setOTP(e);
    setOtp({ ...otp, otp: e });
  };

  const handleGoHome = () => {
    setAuthData(null);
    clearInterval(myInterval);
    setShowTimer(60);
    setShowOtpScreen(false);
  };

  useEffect(() => {
    if (showOtpScreen) {
      runTimer();
    }
  }, [showOtpScreen]);

  const runTimer = () => {
    if (timer > 0) {
      myInterval = setInterval(() => {
        if (showTimer > 0) {
          setShowTimer((prev) => prev - 1);
        }
        if (showTimer === 0) {
          clearInterval(myInterval);
          setShowResend(true);
          setShowTimer(60);
        }
      }, 1000);
    }
  }

  const sendOtpFun = () => {
    const usertype = localStorage.getItem('usertype');
    if (localStorage.getItem("i18nextLng") === "es") {
      sendOTPES(authData.user._id, usertype);
    }
    else {
      sendOTP(authData.user._id, usertype);
    }
    setOtp({ ...otp, otp: '' });
    setShowResend(false);
    runTimer();
  };

  useEffect(() => {
    if (authData && OTP.length === 6) {
      verifyOTP(authData.user?._id, otp);
    }
  }, [OTP, otp, authData]);

  const verifyOTP = async (userid, value) => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/${userid}`,
        value,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        redirectFun(authData);
      })
      .catch((err) => {
        let serverResponse = err;
        toast.error(serverResponse.response.data.msg);
      });
  };

  if (loading) return <Loader />;

  if (showOtpScreen) {
    return (
      <div className="Auth-wrapper-2">
        <div className="logobar">
          <Container className="p-0 text-center">
            <div className="go-back-home">
              <a href="#" onClick={handleGoHome}>
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="27"
                    cy="27"
                    r="26.5"
                    fill="#253837"
                    stroke="#A3EB64"
                  />
                  <path
                    d="M24.9821 22.1376L24.9884 22.1314L24.995 22.1256C25.0335 22.0911 25.0632 22.0505 25.0834 22.007C25.1035 21.9635 25.114 21.9173 25.1149 21.8711C25.1157 21.8249 25.1069 21.7785 25.0885 21.7346C25.07 21.6906 25.0419 21.6493 25.0049 21.6137C24.9679 21.578 24.9226 21.5489 24.8714 21.529C24.8202 21.5091 24.7647 21.4991 24.7085 21.5001C24.6522 21.501 24.5972 21.5129 24.5469 21.5344C24.4967 21.556 24.4527 21.5865 24.4172 21.6232L24.4111 21.6295L24.4047 21.6356L19.6098 26.2488C19.6098 26.2489 19.6097 26.2489 19.6097 26.2489C19.5371 26.319 19.5 26.4097 19.5 26.5C19.5 26.5903 19.5371 26.681 19.6097 26.7511C19.6097 26.7511 19.6098 26.7511 19.6098 26.7512L24.4047 31.3644L24.4111 31.3705L24.4172 31.3768C24.4527 31.4135 24.4967 31.444 24.5469 31.4656C24.5972 31.4871 24.6522 31.499 24.7085 31.4999C24.7647 31.5009 24.8202 31.4909 24.8714 31.471C24.9226 31.4511 24.9679 31.422 25.0049 31.3863C25.0419 31.3507 25.07 31.3094 25.0885 31.2654C25.1069 31.2215 25.1157 31.1751 25.1149 31.1289C25.114 31.0827 25.1035 31.0365 25.0834 30.993C25.0632 30.9495 25.0335 30.909 24.995 30.8744L24.9884 30.8686L24.9821 30.8624L21.7214 27.7253L20.8272 26.865L22.0681 26.865L33.6842 26.865C33.7962 26.865 33.8998 26.822 33.9733 26.7513C34.0462 26.6813 34.0833 26.5904 34.0833 26.5C34.0833 26.4096 34.0462 26.3187 33.9733 26.2487C33.8998 26.178 33.7962 26.135 33.6842 26.135L22.0681 26.135L20.8272 26.135L21.7214 25.2747L24.9821 22.1376Z"
                    fill="#FFB65A"
                    stroke="white"
                  />
                </svg>
                <span>{_t("Go Back To Home")} </span>
              </a>
            </div>
            <img src="../images/logo.svg" id="wrapper-3-logo" alt="logo" />
          </Container>
        </div>
        <Container>
          <div className="authbar-inner">
            <div className="fullbar">
              <div className="right-contentbar">
                {/* OTP Screen  */}
                <div className="resend-text">
                  <h4 className="text-none">
                    {`${_t("OTP Sent On ")} `}
                    {authData?.user?.email}
                  </h4>
                  <p>{_t("Please enter the code sent to your email")}</p>
                  <p style={{ color: "#EF7833" }}>
                    <i>({_t("Note: Kindly check spam as well")}.)</i>
                  </p>
                  <Form className="loginform otp-Verification">
                    <OTPInput
                      className="forminput"
                      value={OTP}
                      onChange={otpinputChange}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                    />
                  </Form>

                  {!showResend && (
                    <p className="timer">
                      {" "}
                      {_t("Resend code")} <span>{showTimer}s</span>
                    </p>
                  )}
                  {showResend && (
                    <Button className="btn-fill" onClick={sendOtpFun}>
                      {_t("Resend OTP")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="terms-bar">
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/terms-conditions.html"
              >
                {_t("Terms & Conditions")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/terms-conditions.html"
              >
                {_t("Terms & Conditions")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/privacy-policy.html"
              >
                {" "}
                {_t("Privacy Policy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/privacy-policy.html"
              >
                {" "}
                {_t("Privacy Policy")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/cookies-es.html"
              >
                {" "}
                {_t("Cookies Policy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/cookies.html"
              >
                {" "}
                {_t("Cookies Policy")}
              </Link>
            )}
          </div>
        </Container>
      </div>
    )
  }

  return (
    <>
      <div className="Auth-wrapper">
        <img
          className="auth-shape-center"
          src="../images/LineAuth.svg"
          alt="logo"
        />

        <div className="Auth-bar">
          <div className="authbar">
            <div className="logobar" onClick={handleRedirectToLandingPage}>
              <Container>
                {userLogin.user_type == constants.MACC ? (
                  <Link to="https://alquilatuhogar.skortorent.com/">
                    <img
                      src="/images/Macc.svg"
                      alt=""
                    />
                  </Link>
                ) : (
                  <Link to="https://skortorent.com/">
                    <img src="../images/logo.svg" alt="logo" />
                  </Link>
                )}
              </Container>
            </div>
            <Container>
              <div className="authbar-inner">
                <div className="leftbar">
                  <div className="hero-center">
                    {localStorage.getItem("usertype") == "tenant" ? (
                      <img src="../images/TenantUserRole.webp" alt="logo" />
                    ) : (
                      <img
                        className="signp_img"
                        src="../images/LandlordUserRole.webp"
                        alt="logo"
                      />
                    )}
                  </div>
                  <div className="emptybar"></div>
                </div>
                <div className="rightbar">
                  <img
                    className="auth-shapetopright"
                    src="../images/handAuth.svg"
                    alt="logo"
                  />
                  <div className="right-contentbar">
                    <div className="backbtnbar">
                      <Link to="/userrole" className="backbtn">
                        <FaArrowLeft />
                        {_t("Go Back")}
                      </Link>
                    </div>
                    <div className="heading_lng_div">
                      {showForgotPassword ? (
                        <h4>{_t("Forgot Password?")}</h4>
                      ) : (
                        <h4>
                          {_t("login as")}{" "}
                          {user_type === "tenant" ? _t("Tenant") : _t("Landlord")}
                        </h4>
                      )}
                      <div class="menu-togglebar">
                        <label class="customradiobtn">
                          <input
                            type="radio"
                            onClick={handleLanguageChange}
                            checked={currentLang == "en-us" ? "checked" : ""}
                            name="radio"
                            value={"en-us"}
                          />
                          <span class="checkmark1">EN</span>
                        </label>
                        <label class="customradiobtn">
                          <input
                            type="radio"
                            onClick={handleLanguageChange}
                            checked={currentLang == "es" ? "checked" : ""}
                            name="radio"
                            value={"es"}
                          />
                          <span class="checkmark1">ES</span>
                        </label>
                      </div>
                    </div>
                    {showForgotPassword ? (
                      <form onSubmit={onSubmitForgotPassword}>
                        <div className="or-bar d-none">
                          <p>&nbsp;</p>
                        </div>
                        <div className=" auth-form d-blockform">
                          <div className="forminput">
                            <label className="forminput">{_t("Email")}</label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="email"
                              required
                              placeholder={_t("Email")}
                              onChange={onChange}
                            />
                          </div>

                          <div className="btngroup">
                            <button className="btn btnorange" type="submit">
                              {_t("Send OTP")}
                            </button>
                            <h4 className="signup-text">
                              {_t("Don't have an account?")}
                              <Link
                                to={`/register#${localStorage.getItem(
                                  "usertype"
                                )}`}
                              >
                                {_t("Sign Up")}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form onSubmit={onSubmit}>
                        <div className="linkedbtn">
                          <Button className="btn-fill" onClick={loginLinkedin}>
                            <BsLinkedin />
                            {_t("Login With LinkedIn")}
                          </Button>
                        </div>

                        <div className="or-bar">
                          <p>{_t("Or")}</p>
                        </div>
                        <div className=" auth-form d-blockform">
                          <div className="forminput">
                            <label className="forminput">{_t("Email")}</label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="email"
                              placeholder={_t("Email")}
                              onChange={onChange}
                            />
                          </div>
                          <div className="forminput">
                            <label className="forminput">
                              {_t("Password")}
                            </label>
                            <div className="password-div">
                              <input
                                type={passwordType}
                                className="form-control user-auth icon-eye"
                                name="password"
                                id="enter_password"
                                placeholder={_t("Password")}
                                onChange={onChange}
                              />

                              {showPassword ? (
                                <VscEye
                                  className="eye-icons"
                                  onClick={(e) => handleShowPassword(false)}
                                />
                              ) : (
                                <VscEyeClosed
                                  className="eye-icons"
                                  onClick={(e) => handleShowPassword(true)}
                                />
                              )}
                            </div>
                          </div>

                          <div className="btngroup">
                            <button className="btn btnorange" type="submit">
                              {_t("Login")}
                            </button>
                            <h4 className="signup-text">
                              {_t("Don't have an account?")}
                              <Link
                                to={`/register#${localStorage.getItem(
                                  "usertype"
                                )}`}
                              >
                                {_t("Sign Up")}
                              </Link>
                            </h4>
                            <h4 className="signup-text">
                              <Link
                                to={undefined}
                                onClick={handleForgotPassword}
                              >
                                {_t("Forgot Password?")}
                              </Link>
                            </h4>
                          </div>
                        </div>

                        {/* 
                      {/* <div className="login-bottom">
                        <h4 className="signup-text">
                          {_t("Don't have an account?")}
                          <Link to="/register">{_t("Sign Up")}</Link>
                        </h4>
                      </div> */}
                      </form>
                    )}
                  </div>
                  <div className="hero-foot">
                    <Container>
                      <div className="terms-bar">
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/terms-conditions.html"
                          >
                            {_t("Terms & Conditions")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/terms-conditions.html"
                          >
                            {_t("Terms & Conditions")}
                          </Link>
                        )}
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/privacy-policy.html"
                          >
                            {" "}
                            {_t("Privacy Policy")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/privacy-policy.html"
                          >
                            {" "}
                            {_t("Privacy Policy")}
                          </Link>
                        )}
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/cookies-es.html"
                          >
                            {" "}
                            {_t("Cookies Policy")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/cookies.html"
                          >
                            {" "}
                            {_t("Cookies Policy")}
                          </Link>
                        )}
                      </div>
                    </Container>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
