import React, { useState, useEffect, useContext } from "react";
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  createRenderer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { useSnackbar } from "material-ui-snackbar-provider";

import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../context/auth/AuthContext";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backicon from "../../../assets/img/back.png";
import UserScoreProfile from "../../common/userProfile/UserScoreProfile";
import useTranslator from "../../../hooks/useTranslator";
import LandLordContext from "../../../context/landlord/LandLordContext";
import { haveValue } from "../../../helper/common";

const ProfileAndProperty = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const sharedprofileid = queryParameters.get("sharedprofileid");
  const { _t } = useTranslator();
  const snackbar = useSnackbar();

  const authContext = useContext(AuthContext);
  const { user, checkIeDetails } = authContext;

  const landLordContext = useContext(LandLordContext);
  const { properties, editSharedProfile, loadProperties } = landLordContext;

  const [fullinfo, setFullinfo] = useState({});
  const [currentActiveAccordion, setCurrentActiveAccordion] = useState("");
  const [loading, setLoading] = useState(false);
  const [propertyLink, setPropertyLink] = useState([]);
  const [defaultPropertyLink, setDefaultPropertyLink] = useState([]);
  const [rentPercentage, setRentPercentage] = useState(1);
  const [selectBtn, setSelectBtn] = useState(_t("Select") + "...");
  const propList = properties.map((prop) => ({
    value: prop?._id,
    label: `${prop?.street} - ${prop?.street_number}, ${prop.floor_number}-${prop.flat_number}`,
  }));

  useEffect(() => {
    let userId = localStorage.getItem("saveduserid");
    loadProperties(userId);
    loadFullInfo();
  }, []);

  useEffect(() => {
    if (haveValue(properties) && haveValue(fullinfo)) {
      if (haveValue(fullinfo.propertyid)) {
        const temp_arr = [];
        var result = fullinfo.propertyid.map((id) => {
          const obj = properties.find((item) => item._id === id);
          if (obj) {
            temp_arr.push(
              `${obj.street} - ${obj.street_number}, ${obj.floor_number}-${obj.flat_number}`
            );
            return {
              value: obj?._id,
              label: `${obj.street} - ${obj.street_number}, ${obj.floor_number}-${obj.flat_number}`,
            };
          }
          return null; // Handle case where id is not found in array1
        });

        result = result.filter((val) => haveValue(val));

        var placeholder_string = [];

        temp_arr.forEach((val) => {
          console.log(val);
          placeholder_string.push(
            <span className="placeholder_string">{val}</span>
          );
        });
        if (placeholder_string.length == 0) setSelectBtn(_t("Select") + "...");
        else setSelectBtn(placeholder_string);

        setPropertyLink(fullinfo?.propertyid);
        setDefaultPropertyLink(result);
      }
    }
    if (haveValue(fullinfo)) {
      console.log(fullinfo?.rent_percentage, fullinfo?.landlordid);
      const temp =
        fullinfo?.rent_percentage || fullinfo?.landlordid?.rent_percentage || 0;
      setRentPercentage(temp);
    }
  }, [properties, fullinfo]);

  const loadFullInfo = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/shared-profile/singleshared/${sharedprofileid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        let server_res = res;

        if (server_res.data.singleSharedProfile?.tenantid?.ie_details_id) {
          server_res.data.singleSharedProfile.tenantid.ie_verified =
            checkIeDetails(server_res.data.singleSharedProfile?.tenantid);
        }

        setFullinfo(server_res.data.singleSharedProfile);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleLinkProperty = async (e, filtered = null) => {
    var sendData = null;
    if (haveValue(propertyLink)) {
      sendData = propertyLink;
    }
    if (haveValue(filtered)) {
      sendData = filtered;
    }
    if (haveValue(sendData)) {
      // console.log([...sendData],)
      setLoading(true);
      const data = {
        property_id: sendData,
        // property_id: properties[propertyLink]._id
      };
      await editSharedProfile(fullinfo?._id, data)
        .then(async (res) => {
          await loadFullInfo();
          if (haveValue(filtered)) {
            snackbar.showMessage(_t("Property Un-Linked!"));
          } else {
            snackbar.showMessage(_t("Property Linked!"));
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      toast.error(_t("Please Select Property To Link!"));
    }
  };

  const handleUnlinkProperty = (propertyId) => {
    const temp = [...propertyLink];

    const filtered = temp.filter((val) => val != propertyId);

    setPropertyLink(filtered);
    handleLinkProperty({}, filtered);
  };

  const handlePropSelect = async (e) => {
    const temp = e.map((val) => val.value);
    console.log(selectBtn);
    var placeholder_string = [];

    const tempArr = propList.filter((val) => temp.includes(val?.value));

    tempArr.forEach((val) => {
      placeholder_string.push(
        <span className="placeholder_string">{val?.label}</span>
      );
    });

    if (placeholder_string.length == 0) setSelectBtn(_t("Select") + "...");
    else setSelectBtn(placeholder_string);
    setPropertyLink(temp);
  };

  console.log("check rent percentage here", rentPercentage);
  if (loading) return <Loader />;
  return (
    <>
      {fullinfo?.tenantid && (
        <>
          <div className="tabs-box-pattern-2">
            <div className="tabs-item-1 d-block">
              <div className="row">
                <div className="header-edit text-center space-custom d-flex items-center justify-content-between">
                  <div>
                    {" "}
                    <Link to="/profile-request">
                      <img src={backicon} alt="backbtn" />
                    </Link>
                  </div>

                  <h3>{_t("Tenant Profile And Property")}</h3>
                  <div></div>
                </div>
              </div>
              <div className="row">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="edit-profile">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="edit-profile">
                          <UserScoreProfile
                            properties={properties}
                            handleUnlinkProperty={handleUnlinkProperty}
                            fullinfo={fullinfo}
                            currentActiveAccordion={currentActiveAccordion}
                            setCurrentActiveAccordion={
                              setCurrentActiveAccordion
                            }
                            name={"hello"}
                            rent_from_income={
                              fullinfo?.rent_from_income || null
                            }
                            scoreProfile={fullinfo?.tenantid}
                            defaultPropertyLink={defaultPropertyLink}
                            propList={propList}
                            selectBtn={selectBtn}
                            handlePropSelect={handlePropSelect}
                            handleLinkProperty={handleLinkProperty}
                            propertyLink={propertyLink}
                            rentPercentage={rentPercentage}
                            setRentPercentage={setRentPercentage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileAndProperty;
