import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { toast } from "react-toastify";
import AuthContext from "../../../context/auth/AuthContext";
import { haveValue } from "../../../helper/common";
import LandLordContext from "../../../context/landlord/LandLordContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cadastre from "../../../components/Property/Cadastre";
const AddPropertyNew = () => {
  // Confir modal
  const [show, setShow] = useState(false);
  const [notFoundModal, setNotFoundModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (
      haveValue(formData?.street_type) &&
      haveValue(formData?.street) &&
      haveValue(formData?.street_number) &&
      haveValue(formData?.floor) &&
      haveValue(formData?.door) &&
      haveValue(formData?.province) &&
      haveValue(formData?.postcode)
    ) {
      setShow(true);
    } else {
      setNotFoundModal(true);
    }
  };

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const landLordContext = useContext(LandLordContext);

  const { addProperty, properties, loadProperties } = landLordContext;

  const navigate = useNavigate();

  const { _t } = useTranslator();
  const [step, setStep] = useState(1);

  const defaultState = {
    street_type: "",
    street: "",
    street_number: "",
    postcode: "",
    staircase: "",
    floor: "",
    door: "",
    municipality: "",
    province: "",
    code_ine: "",
    code_via: "",
    cadastral_reference: "",
    area: "",
    property_reference: "",
  };
  const [formData, setFormData] = useState(defaultState);

  useEffect(() => {
    let userid = localStorage.getItem("saveduserid");
    loadProperties(userid);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const check = properties.filter(
      (prop) =>
        prop?.street == `${formData?.street_type}, ${formData?.street}` &&
        prop?.street_number == formData?.street_number &&
        prop?.floor_number == formData?.floor &&
        prop?.flat_number == formData?.door &&
        prop?.muncipality == formData?.municipality &&
        prop?.province == formData?.province
    );

    if (check.length > 0) {
      toast.error(_t("Property Already Exists!"));
    } else {
      await addProperty(user?._id, formData);
      console.log("Property Added");
      navigate("/home");
    }
  };

  const handleGoHome = () => {
    navigate("/home");
  };

  return (
    <>
      <div className="Property-wrapper">
        <div className="backbtn_bar">
          <button type="button" className="btnback" onClick={handleGoHome}>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L1 9L9 17"
                stroke="#121212"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        {
          <Container>
            <div className="authbar-inner">
              <div className="fullbar">
                <Cadastre
                  step={step}
                  setStep={setStep}
                  handleShow={handleShow}
                  formData={formData}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  defaultState={defaultState}
                  handleSubmit={handleSubmit}
                  isLandlord={true}
                />
              </div>
            </div>
          </Container>
        }
        <Container></Container>
      </div>
      <Modal show={show} className="metermodal" centered>
        <Modal.Body>
          <Form className="loginform" onSubmit={handleSubmit}>
            <h4>{_t("Is This Your Address?")}</h4>
            <p>
              {`
                            ${formData?.street_type}, ${formData?.street} ${formData?.street_number}, ${formData?.floor} - ${formData?.door}
                            ${formData?.province}, ${formData?.postcode}
                            `}
            </p>
            <textarea
              name="property_reference"
              className="w-100"
              id="property_reference"
              rows="2"
              onChange={handleInputChange}
              placeholder={_t("Property Reference (Optional)")}
            >
              {formData?.property_reference}
            </textarea>
            <div className="auth-form">
              <div className="btngroup row100 gridbar">
                <button
                  className="btn btnoutline"
                  type="button"
                  onClick={(e) => {
                    setStep(3);
                    handleClose();
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.9815 2.07364L12.9815 2.07366L8.05406 7L12.9815 11.9263L12.9815 11.9264C13.1214 12.0663 13.2 12.256 13.2 12.4539C13.2 12.6518 13.1214 12.8416 12.9815 12.9815C12.8416 13.1214 12.6518 13.2 12.4539 13.2C12.256 13.2 12.0663 13.1214 11.9264 12.9815L11.9263 12.9815L7 8.05406L2.07366 12.9815L2.07364 12.9815C1.93372 13.1214 1.74395 13.2 1.54608 13.2C1.34821 13.2 1.15844 13.1214 1.01852 12.9815C0.878604 12.8416 0.8 12.6518 0.8 12.4539C0.8 12.256 0.878605 12.0663 1.01852 11.9264L1.01854 11.9263L5.94594 7L1.01854 2.07366L1.01852 2.07364C0.949242 2.00436 0.894286 1.92211 0.856792 1.83159C0.819298 1.74107 0.8 1.64406 0.8 1.54608C0.8 1.4481 0.819298 1.35109 0.856792 1.26057C0.894286 1.17005 0.949242 1.0878 1.01852 1.01852C1.0878 0.949242 1.17005 0.894286 1.26057 0.856792C1.35109 0.819298 1.4481 0.8 1.54608 0.8C1.64406 0.8 1.74107 0.819298 1.83159 0.856792C1.92211 0.894286 2.00436 0.949242 2.07364 1.01852L2.07366 1.01854L7 5.94594L11.9263 1.01854L11.9264 1.01852C11.9956 0.949242 12.0779 0.894286 12.1684 0.856792C12.2589 0.819298 12.3559 0.8 12.4539 0.8C12.5519 0.8 12.6489 0.819298 12.7394 0.856792C12.83 0.894286 12.9122 0.949242 12.9815 1.01852M12.9815 2.07364L12.9815 1.01852M12.9815 2.07364C13.0508 2.00436 13.1057 1.92211 13.1432 1.83159C13.1807 1.74107 13.2 1.64406 13.2 1.54608C13.2 1.44811 13.1807 1.35109 13.1432 1.26057C13.1057 1.17005 13.0508 1.0878 12.9815 1.01852M12.9815 2.07364L12.9815 1.01852"
                      fill="#253837"
                      stroke="#253837"
                      stroke-width="0.4"
                    />
                  </svg>
                  {_t("Incorrect")}
                </button>
                <button className="btn btngreen" type="submit">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 1.26174L5.02857 12L0 7.0783L1.28914 5.81656L5.02857 9.46756L14.7109 0L16 1.26174Z"
                      fill="#253837"
                    />
                  </svg>
                  {_t("Correct")}
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={notFoundModal}
        className="metermodal"
        centered
        onHide={(e) => setNotFoundModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form className="loginform">
            {/* <h4>{_t("Property Not Found")}</h4> */}
            <h4>{_t("Ups! It seems we are missing some information")}</h4>
            <div className="auth-form">
              <div className="btngroup row100">
                <button
                  className="btn btnorange"
                  type="button"
                  onClick={(e) => {
                    setStep(3);
                    setNotFoundModal(false);
                  }}
                >
                  {_t("Update Manually")}
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPropertyNew;
