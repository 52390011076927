import { Container, Form, Modal } from "react-bootstrap";
import Cadastre from "../../../components/Property/Cadastre";
import useTranslator from "../../../hooks/useTranslator";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  haveValue,
  renderErrorMessage,
  validateJOIProperty,
  validateJOIFormField,
} from "../../../helper/common";
import TenantContext from "../../../context/tenant/TenantContext";
import { toast } from "react-toastify";
import AuthContext from "../../../context/auth/AuthContext";
import constants from "../../../Utils/constants";
import moment from "moment";
import LandLordContext from "../../../context/landlord/LandLordContext";
import * as Joi from "joi-browser";
import CardSelect from "../../../components/Tenant/CardSelect";
import RentMonthPaidModal from "../../../components/Tenant/RentMonthPaidModal";

const TenantRentalStep = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const landLordContext = useContext(LandLordContext);
  const { fetchAssignedDetails } = landLordContext;
  const { user, setLoading } = authContext;
  const { rePayRent, rentPayment, getRentalInfo, ledgerData } = tenantContext;
  const queryParameters = new URLSearchParams(window.location.search);
  const payment_success_check = queryParameters.get("success");
  const repay_check_success = queryParameters.get("repay");
  const revisit_payment = queryParameters.get("revisit");
  const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const defaultStateProp = {
    street_type: "",
    street: "",
    street_number: "",
    postcode: "",
    staircase: "",
    floor: "",
    door: "",
    municipality: "",
    province: "",
    code_ine: "",
    code_via: "",
    cadastral_reference: "",
    area: "",
    property_reference: "",
  };
  const defaultForm = {
    ...defaultStateProp,
    landlord_fname: "",
    landlord_lname: "",
    landlord_email: "",
    landlord_account_number: "",
    rent_amount: null,
    deposit_amount: 0,
    rent_date: null,
    selected_month: "",
  };
  const [formData, setFormData] = useState(defaultForm);
  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [errors, setErrors] = useState({});
  const [isDomesticCard, setIsDomesticCard] = useState(null);
  const [cardModal, setCardModal] = useState(false);
  const [continueModal, setContinueModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [notFoundModal, setNotFoundModal] = useState(false);

  useEffect(() => {
    if (haveValue(repay_check_success)) {
      if (repay_check_success == true || repay_check_success == "true") {
        setCurrentStep(5);
      }
    }
    if (haveValue(payment_success_check)) {
      if (payment_success_check == true || payment_success_check == "true") {
        localStorage.removeItem("rental_info");
        setCurrentStep(5);
      }
      if (payment_success_check == false || payment_success_check == "false") {
        setCurrentStep(0);
        const prefilled_vals = localStorage.getItem("rental_info");
        if (haveValue(prefilled_vals)) {
          setFormData(JSON.parse(prefilled_vals));
        }
        toast.error(_t("Payment Failed!"));
      }
    }
    if (haveValue(revisit_payment) && revisit_payment) {
      setLoading(true);
      setCurrentStep(6);
      setIsEdit(true);
      // navigate("/tenant/rentalStep");
    } else {
      setIsEdit(false);
    }

    ledgerData()
      .then((res) => {
        const data = res.success_res;
        const temp_data = data.filter(
          (val) => val.status == constants.COMPLETED
        );
        setPaymentHistory(temp_data);
      })
      .catch((err) => {
        console.log(err, "Error in ledgerData");
      });
  }, []);

  useEffect(() => {
    if (user?.rental_data?.type == constants.RENTAL_INFO) {
      getRentalInfo()
        .then((res) => {
          setFormData(res.success_res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(true);
          console.log(err);
        });
    } else if (user?.rental_data?.type == constants.ASSIGNED_PROPERTY) {
      fetchAssignedDetails(user?.rental_data?.id)
        .then((res) => {
          setFormData(res.result);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(true);
          console.log(err);
        });
    } else {
      if (haveValue(user)) {
        setLoading(false);
        setIsEdit(false);
        setCurrentStep(0);
        setStep(1);
      }
      // navigate("/tenant/rentalStep");
    }
  }, [user]);

  const navigateHome = () => {
    navigate("/tenant");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "landlord_account_number") {
      const temp = value.split(" ").join("");
      e.target.value = temp;
      setFormData((prevData) => ({ ...prevData, [name]: temp }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    if (name in formData) {
      const errorData = { ...errors };
      const errorMessage = validateJOIProperty(getFieldsSchema(), e);
      if (errorMessage) {
        errorData[name] = errorMessage;
      } else {
        errorData[name] && delete errorData[name];
      }
      setErrors(errorData);
    }
  };

  const handleMonthChange = (e) => {
    const { name, value } = e.target;
    const rent_date = moment().month(value).format("YYYY-MM-DD");
    setFormData((prevData) => ({
      ...prevData,
      [name]: rent_date,
      selected_month: value,
    }));
  };

  const handleGoNext = () => {
    console.log(formData, "check formdata here");
    let errors = validateJOIFormField(formData, getFieldsSchema());
    if (errors) {
      setErrors(errors);
      return;
    }
    if (
      currentStep == 1 &&
      (!haveValue(formData?.street_type) ||
        !haveValue(formData?.street) ||
        !haveValue(formData?.street_number) ||
        !haveValue(formData?.floor) ||
        !haveValue(formData?.door) ||
        !haveValue(formData?.province) ||
        !haveValue(formData?.postcode))
    ) {
      setNotFoundModal(true);
      return;
    }
    setCurrentStep((prev) => ++prev);
  };

  const handleGoBack = () => {
    setCurrentStep((prev) => --prev);
  };

  const handleGoBackCadastre = () => {
    setCurrentStep((prev) => --prev);
    setStep(1);
  };

  const handlePaymentInitiate = () => {
    const temp = {
      ...formData,
      tenant_id: localStorage.getItem("saveduserid"),
      isDomesticCard,
    };
    localStorage.setItem("rental_info", JSON.stringify(temp));
    rentPayment(temp)
      .then((res) => {
        window.location.replace(res.success_res.session.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateBackHome = () => {
    navigate("/tenant");
  };

  const getPaymentMonths = () => {
    const currentMonth = moment().month();
    const previousMonth = moment().clone().subtract(1, "months").month();
    const nextMonth = moment().clone().add(1, "months").month();

    return (
      <>
        <option value="" selected>
          {_t("Select Payment Month")}
        </option>
        <option value={previousMonth}>{Months[previousMonth]}</option>
        <option value={currentMonth}>{Months[currentMonth]}</option>
        <option value={nextMonth}>{Months[nextMonth]}</option>
      </>
    );
  };

  const initiateNewFlow = () => {
    setCurrentStep(0);
    setIsEdit(false);
    if (user?.rental_data?.type != constants.RENTAL_INFO) {
      setFormData(defaultForm);
    }
  };

  const changeMonthAndYear = (dateString, newMonth) => {
    const date = moment(dateString);
    const currentYear = moment().year();

    date.month(newMonth); // Set the new month (0-11)
    date.year(currentYear); // Set the current year

    return date.toISOString(); // Return the updated date as an ISO string
  };

  const rePaymentRent = () => {
    const originalDate = formData?.rent_date;
    const newMonth = +selectedMonth;
    var rent_date = changeMonthAndYear(originalDate, newMonth);

    const temp = {
      tenant_id: localStorage.getItem("saveduserid"),
      rental_info_id: user?.rental_data?.id,
      rent_date,
      isDomesticCard,
    };
    rePayRent(temp)
      .then((res) => {
        window.location.replace(res.success_res.session.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Schema
  const getFieldsSchema = () => {
    return {
      landlord_account_number: Joi.string()
        .length(24)
        .regex(/^ES\d{22}$/)
        .required()
        .error((errors) => {
          // Return the same error message for all validation errors
          errors.forEach((err) => {
            err.message = _t(
              "The account number must be exactly 24 characters long and start with 'ES' followed by 22 digits."
            );
          });
          return errors;
        }),
    };
  };

  const handlePayRent = () => {
    var already_found = false;
    for (let val of paymentHistory) {
      if (
        selectedMonth == moment(val.processing_date).month() &&
        moment(val.processing_date).year() == moment().year()
      ) {
        already_found = true;
        break;
      }
    }
    if (already_found) {
      setContinueModal(true);
    } else {
      setCardModal(true);
    }
  };

  console.log(formData, "check formData");

  return (
    <>
      <div className="step-form-main-wrapper">
        <div className="d-flex step-absolute-header step-form-header">
          <Container className="p-0 text-center">
            <div className="d-flex justify-content-between align-items-center">
              <div className="back-btn-mobile">
                <a
                  className="d-flex gap-2 align-items-center back-btn"
                  href={undefined}
                  onClick={navigateHome}
                >
                  <img src="/images/back-btn-white.svg" alt="" />
                  {/* <span>{_t("Go Back To Home")} </span> */}
                </a>
              </div>

              <h3 className="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837" />
                  <path
                    d="M32.12 24.88C31.5577 24.3177 30.7952 24.0019 30 24.0019C29.2048 24.0019 28.4423 24.3177 27.88 24.88C27.3177 25.4423 27.0019 26.2048 27.0019 27C27.0019 27.7952 27.3177 28.5577 27.88 29.12C28.1584 29.3984 28.4889 29.6192 28.8527 29.7699C29.2164 29.9206 29.6063 29.9981 30 29.9981C30.3937 29.9981 30.7836 29.9206 31.1473 29.7699C31.5111 29.6192 31.8416 29.3984 32.12 29.12C32.3984 28.8416 32.6192 28.5111 32.7699 28.1473C32.9206 27.7836 32.9981 27.3937 32.9981 27C32.9981 26.6063 32.9206 26.2164 32.7699 25.8527C32.6192 25.4889 32.3984 25.1584 32.12 24.88ZM22 21V33H38V21H22ZM36 29C35.47 29 34.96 29.21 34.59 29.59C34.21 29.96 34 30.47 34 31H26C26 30.47 25.79 29.96 25.41 29.59C25.04 29.21 24.53 29 24 29V25C24.53 25 25.04 24.79 25.41 24.41C25.79 24.04 26 23.53 26 23H34C34 23.53 34.21 24.04 34.59 24.41C34.96 24.79 35.47 25 36 25V29ZM20 23H18C17.45 23 17 22.55 17 22C17 21.45 17.45 21 18 21H20V23ZM20 28H17C16.45 28 16 27.55 16 27C16 26.45 16.45 26 17 26H20V28ZM20 33H16C15.448 33 15 32.55 15 32C15 31.45 15.448 31 16 31H20V33Z"
                    fill="#A3EB64"
                  />
                </svg>
                <span>{_t("Monthly Rent Payment")}</span>{" "}
              </h3>
              <p className="step-count">
                {currentStep < 5 && (
                  <>
                    <span>
                      {"Step"} {currentStep + 1}
                    </span>{" "}
                    of 5
                  </>
                )}
              </p>
            </div>
          </Container>
        </div>
        {currentStep < 5 && (
          <div className="logobarwrapper">
            <Container className="progress-bar">
              <ul className="progress-ul">
                <li
                  className={`progress-li ${
                    currentStep == 0 ? "current-progress" : ""
                  }  ${currentStep > 0 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${
                    currentStep == 1 ? "current-progress" : ""
                  }  ${currentStep > 1 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${
                    currentStep == 2 ? "current-progress" : ""
                  }  ${currentStep > 2 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${
                    currentStep == 3 ? "current-progress" : ""
                  }  ${currentStep > 3 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${
                    currentStep == 4 ? "current-progress" : ""
                  }  ${currentStep > 4 ? "current-completed" : ""}`}
                ></li>
              </ul>
            </Container>
          </div>
        )}
        <div>
          {currentStep == 0 && (
            <>
              <div className="steps-wrapper">
                <h4 className="step-name">{_t("Landlord details")}</h4>
                <div className="forminput">
                  <p className="label-text">{_t("First Name")}</p>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="landlord_fname"
                    value={formData.landlord_fname}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter First Name")}
                  />
                </div>
                <div className="forminput">
                  <p className="label-text">{_t("Last Name")}</p>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="landlord_lname"
                    value={formData.landlord_lname}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Last Name")}
                  />
                </div>
                <div className="forminput">
                  <p className="label-text">{_t("Email")}</p>
                  <input
                    type="email"
                    className="form-control user-auth"
                    name="landlord_email"
                    value={formData.landlord_email}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Email")}
                  />
                </div>
                <div className="forminput">
                  <p className="label-text">{_t("Bank Account Number")}</p>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="landlord_account_number"
                    value={formData.landlord_account_number}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("XXXXXXXXXXXX")}
                  />
                  <label
                    className="forminput password_error"
                    dangerouslySetInnerHTML={{
                      __html: renderErrorMessage(
                        errors,
                        "landlord_account_number"
                      ),
                    }}
                  />
                </div>
                <button
                  onClick={handleGoNext}
                  className="btn btnorange step-btn"
                  disabled={
                    !haveValue(formData.landlord_fname) ||
                    !haveValue(formData.landlord_lname) ||
                    !haveValue(formData.landlord_email) ||
                    !haveValue(formData.landlord_account_number)
                  }
                >
                  {_t("Next")}
                </button>
              </div>
            </>
          )}
          {currentStep == 1 && (
            <Cadastre
              step={step}
              setStep={setStep}
              formData={formData}
              setFormData={setFormData}
              handleInputChange={handleInputChange}
              defaultState={defaultStateProp}
              handleShow={handleGoNext}
              handleSubmit={handleGoNext}
              handleBackCurrent={handleGoBack}
            />
          )}
          {currentStep == 2 && (
            <>
              <div className="steps-wrapper">
                <h4 className="step-name">
                  {_t("Tell us about your rental payments")}
                </h4>
                {/* <div className="auth-form">
                  <div className="w-100"> */}
                <div className="forminput">
                  <p className="label-text">{_t("Rental payment amount")}</p>
                  <input
                    type="number"
                    className="form-control user-auth"
                    name="rent_amount"
                    value={formData.rent_amount}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter payment amount")}
                  />
                </div>
                <div className="forminput">
                  <p className="label-text">
                    {_t("Deposit amount (Optional)")}
                  </p>
                  <input
                    type="number"
                    className="form-control user-auth"
                    name="deposit_amount"
                    value={formData.deposit_amount}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter deposit amount (Optional)")}
                  />
                </div>
                <div className="d-flex align-items-center btngroup gap-3 step-btns-group">
                  <button
                    className="btn btnoutline bg-none step-btn"
                    onClick={handleGoBackCadastre}
                  >
                    {_t("Go Back")}
                  </button>
                  <button
                    className="btn btnorange step-btn"
                    onClick={handleGoNext}
                    disabled={!haveValue(formData.rent_amount)}
                  >
                    {_t("Next")}
                  </button>
                </div>
              </div>
            </>
          )}
          {currentStep == 3 && (
            <>
              <div className="steps-wrapper">
                <h4 className="step-name">{_t("Pay Rent For")}</h4>
                <select
                  className="form-control"
                  name="rent_date"
                  required
                  value={formData.selected_month}
                  onChange={handleMonthChange}
                >
                  {getPaymentMonths()}
                </select>
                <div className="d-flex align-items-center btngroup gap-3 step-btns-group">
                  <button
                    className="btn btnoutline  bg-none step-btn"
                    onClick={handleGoBack}
                  >
                    {_t("Go Back")}
                  </button>
                  <button
                    className="btn btnorange step-btn"
                    onClick={handleGoNext}
                    disabled={
                      !haveValue(formData.rent_date) ||
                      !haveValue(formData.selected_month)
                    }
                  >
                    {_t("Next")}
                  </button>
                </div>
              </div>
            </>
          )}
          {currentStep == 4 && (
            <>
              <div className=" steps-wrapper">
                <h4 className="step-name">{_t("Review lease information")}</h4>
                <div className="resend-text">
                  <p className="step-inner-head">{_t("Address")}</p>
                  <p className="step-inner-desc">
                    {`
                ${formData?.street_type}, ${formData?.street} ${formData?.street_number}, ${formData?.floor} - ${formData?.door}
                ${formData?.province}, ${formData?.postcode}
            `}
                  </p>
                  <p className="step-inner-head">{_t("Payment Date")}</p>
                  <p className="step-inner-desc">{formData?.rent_date}</p>
                  <div className="row">
                    <div className="col-6">
                      <h5 className="step-inner-head">
                        {_t("Rental payment amount")}
                      </h5>
                      <p className="step-inner-desc">
                        €{formData?.rent_amount}
                      </p>
                    </div>
                    {haveValue(formData?.deposit_amount) && (
                      <div className="col-6">
                        <h5 className="step-inner-head">
                          {_t("Deposit Amount")}
                        </h5>
                        <p className="step-inner-desc">
                          €{formData?.deposit_amount}
                        </p>
                      </div>
                    )}
                  </div>
                  <p className="help-text">
                    {_t("Protect your home and your stuff—in minutes! ")}
                  </p>
                </div>
                {/* <div className="auth-form"> */}
                <div className="d-flex align-items-center justify-content-center mt-4 btngroup gap-3 step-btns-group">
                  <button
                    className="btn btnoutline bg-none step-btn"
                    onClick={handleGoBack}
                  >
                    {_t("Go Back")}
                  </button>
                  <button
                    className="btn btnorange step-btn"
                    // onClick={handlePaymentInitiate}
                    onClick={(e) => setCardModal(true)}
                  >
                    {_t("Next")}
                  </button>
                </div>
                {/* </div> */}
              </div>
            </>
          )}
          {currentStep == 5 && (
            <>
              <div className="step-complete-reward steps-wrapper">
                <div className="rewards-points">
                  <span className="points">
                    {repay_check_success == true ||
                    repay_check_success == "true"
                      ? 10
                      : 100}
                  </span>
                  <p>
                    {repay_check_success == true ||
                    repay_check_success == "true"
                      ? _t("Skor Points")
                      : _t("Reward Points")}
                  </p>
                </div>
                <p className="greeting-text">{_t("Congratulations")}</p>
                <p className="points-info">
                  {repay_check_success == true || repay_check_success == "true"
                    ? _t(
                        "You have completed your monthly rent payment set up and have been rewarded with 10 Skor points"
                      )
                    : _t(
                        "You have completed your monthly rent payment set up and have been rewarded with 100 points"
                      )}
                </p>
                <button className="step-go-home-btn" onClick={navigateBackHome}>
                  {_t("Go Back home")}
                </button>
              </div>
            </>
          )}
          {currentStep == 6 && (
            <>
              <div className="steps-wrapper">
                <h3 className="step-name mt-5">
                  {_t("Review lease information")}
                </h3>
                <div className="resend-text p-0">
                  <div className="green-box">
                    <h5 className="step-inner-head">{_t("Address")}</h5>
                    <p className="step-inner-desc">
                      {user?.rental_data?.type == constants.RENTAL_INFO
                        ? `${formData?.street} ${formData?.street_number}, ${formData?.floor_number} - ${formData?.flat_number} ${formData?.province}, ${formData?.postcode}`
                        : `${formData?.property_id?.street} ${formData?.property_id?.street_number}, ${formData?.property_id?.floor_number} - ${formData?.property_id?.flat_number} ${formData?.property_id?.province}, ${formData?.property_id?.postcode}`}
                    </p>
                  </div>
                  <div className="lease-info">
                    <div>
                      <h5 className="step-inner-head">{_t("Landlord Name")}</h5>
                      <p className="step-inner-desc">
                        {user?.rental_data?.type == constants.RENTAL_INFO ? (
                          <>
                            {formData?.landlord_fname}{" "}
                            {formData?.landlord_lname}
                          </>
                        ) : (
                          <>
                            {formData?.landlord_id?.fname_companyName}{" "}
                            {formData?.landlord_id?.lname}
                          </>
                        )}
                      </p>
                      <h5 className="step-inner-head">
                        {_t("Landlord Email")}
                      </h5>
                      <p
                        className="step-inner-desc"
                        style={{ textTransform: "unset !important" }}
                      >
                        {user?.rental_data?.type == constants.RENTAL_INFO
                          ? formData?.landlord_email
                          : formData?.landlord_id?.email}
                      </p>
                      <h5 className="step-inner-head">
                        {_t("Rental payment amount")}
                      </h5>
                      <p className="step-inner-desc">
                        €{formData?.rent_amount}
                      </p>
                      <h5 className="step-inner-head">{_t("Payment Month")}</h5>
                      <select
                        className="form-control"
                        onChange={(e) => setSelectedMonth(e.target.value)}
                      >
                        {getPaymentMonths()}
                      </select>
                    </div>
                    <div>
                      <p className="help-text mt-3">
                        {_t("Protect your home and your stuff—in minutes!")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4 btngroup gap-3 step-btns-group mobile-full-btn">
                  <button
                    className="btn btnoutline bg-none step-btn"
                    onClick={initiateNewFlow}
                  >
                    {_t("Edit Details")}
                  </button>
                  <button
                    className="btn btnorange step-btn"
                    // onClick={rePaymentRent}
                    onClick={handlePayRent}
                    disabled={!haveValue(selectedMonth)}
                  >
                    {_t("Proceed to Pay")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {cardModal && (
        <CardSelect
          cardModal={cardModal}
          setCardModal={setCardModal}
          setIsDomesticCard={setIsDomesticCard}
          isDomesticCard={isDomesticCard}
          proceedPayment={isEdit ? rePaymentRent : handlePaymentInitiate}
        />
      )}
      {continueModal && (
        <RentMonthPaidModal
          show={continueModal}
          handleClose={(e) => setContinueModal(false)}
          handleContinue={(e) => {
            setContinueModal(false);
            setCardModal(true);
          }}
        />
      )}
      <Modal
        show={notFoundModal}
        className="metermodal"
        centered
        onHide={(e) => setNotFoundModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form className="loginform">
            <h4>
              {_t(
                "Unable To Fetch Property Details With Given Cadastre Reference"
              )}
            </h4>
            <div className="auth-form">
              <div className="btngroup row100">
                <button
                  className="btn btnorange"
                  type="button"
                  onClick={(e) => {
                    setStep(3);
                    setNotFoundModal(false);
                  }}
                >
                  {_t("Enter Manually")}
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantRentalStep;
