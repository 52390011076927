import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTranslator from "../hooks/useTranslator";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { haveValue } from "../helper/common";
import AuthContext from "../context/auth/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import constants from "../Utils/constants";
import ug_courses from "../Utils/ug_courses.json";
import master_courses from "../Utils/master_courses.json";
import TenantContext from "../context/tenant/TenantContext";
import PdfRender from "../components/PdfRender";
import exclamation from "../assets/img/exclamation.svg";

const StepFormUniversity = () => {
  const authContext = useContext(AuthContext);
  const { user, setLoading, loadUser, setLoggedInUser, toggleLogout } =
    authContext;
  const tenantContext = useContext(TenantContext);
  const { uploadFileData } = tenantContext;

  const navigate = useNavigate();

  const { _t } = useTranslator();
  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [fileData, setFileData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [goHomeConfirm, setGoHomeConfirm] = useState(false);
  const [fileDataState, setFileDataState] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, ["phone"]: phoneNumber }));
  }, [phoneNumber]);

  const applyProfileData = () => {
    setFormData({
      fname: user?.fname,
      lname: user?.lname,
      fname_companyName: user?.fname_companyName,
      type_of_student: "",
      name_of_university: "IE University, Spain",
      employment_status: "student",
      current_annual_income: "",
      email_of_university: user?.email,
      email: user?.email,
      current_course: "",
      name_of_current_university: "",
      university_end_date: "",
      ie_student_id: "",
      lang: localStorage.getItem("i18nextLng"),
    });
  };

  useEffect(() => {
    applyProfileData();
  }, [user]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    loadUser(usertype);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setStep((prevStep) => prevStep + 1);
    setCurrentStep((prev) => prev + 1);
  };

  const handleGoBack = () => {
    setStep((prevStep) => prevStep - 1);
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await uploadFileData(fileData);

    console.log(formData, "CHECK FORM DATA");

    const usertype = localStorage.getItem("usertype");
    formData.role = usertype;

    let userid = localStorage.getItem("saveduserid");
    createUserProfile(userid, formData);
  };

  const createUserProfile = async (id, profileData) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`,
        profileData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then(async (res) => {
        setLoading(false);
        const usertype = localStorage.getItem("usertype");
        await setLoggedInUser();
        await loadUser(usertype);
        navigate("/tenant");
      })
      .catch((err) => {
        setLoading(false);
        let serverResponse = err;
        toast.error(serverResponse.response.data.error);
      });
  };

  const handleGoHomeConfirm = () => {
    toggleLogout();
    navigate("/university/ie/login");
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;

    const formdata = new FormData();

    formdata.append("tenantDocs", files[0]);
    formdata.append("fileType", constants.ie_student);
    setFileData(formdata);
    setFileDataState(e);
    // await uploadFileData(formdata);
  };

  return (
    <>
      <div className="Auth-wrapper-2">
        <div className="logobarwrapper">
          <div className="logobar">
            <Container className="p-0 text-center">
              <div className="go-back-home">
                <a href={undefined} onClick={(e) => setGoHomeConfirm(true)}>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="27"
                      cy="27"
                      r="26.5"
                      fill="#253837"
                      stroke="#A3EB64"
                    />
                    <path
                      d="M24.9821 22.1376L24.9884 22.1314L24.995 22.1256C25.0335 22.0911 25.0632 22.0505 25.0834 22.007C25.1035 21.9635 25.114 21.9173 25.1149 21.8711C25.1157 21.8249 25.1069 21.7785 25.0885 21.7346C25.07 21.6906 25.0419 21.6493 25.0049 21.6137C24.9679 21.578 24.9226 21.5489 24.8714 21.529C24.8202 21.5091 24.7647 21.4991 24.7085 21.5001C24.6522 21.501 24.5972 21.5129 24.5469 21.5344C24.4967 21.556 24.4527 21.5865 24.4172 21.6232L24.4111 21.6295L24.4047 21.6356L19.6098 26.2488C19.6098 26.2489 19.6097 26.2489 19.6097 26.2489C19.5371 26.319 19.5 26.4097 19.5 26.5C19.5 26.5903 19.5371 26.681 19.6097 26.7511C19.6097 26.7511 19.6098 26.7511 19.6098 26.7512L24.4047 31.3644L24.4111 31.3705L24.4172 31.3768C24.4527 31.4135 24.4967 31.444 24.5469 31.4656C24.5972 31.4871 24.6522 31.499 24.7085 31.4999C24.7647 31.5009 24.8202 31.4909 24.8714 31.471C24.9226 31.4511 24.9679 31.422 25.0049 31.3863C25.0419 31.3507 25.07 31.3094 25.0885 31.2654C25.1069 31.2215 25.1157 31.1751 25.1149 31.1289C25.114 31.0827 25.1035 31.0365 25.0834 30.993C25.0632 30.9495 25.0335 30.909 24.995 30.8744L24.9884 30.8686L24.9821 30.8624L21.7214 27.7253L20.8272 26.865L22.0681 26.865L33.6842 26.865C33.7962 26.865 33.8998 26.822 33.9733 26.7513C34.0462 26.6813 34.0833 26.5904 34.0833 26.5C34.0833 26.4096 34.0462 26.3187 33.9733 26.2487C33.8998 26.178 33.7962 26.135 33.6842 26.135L22.0681 26.135L20.8272 26.135L21.7214 25.2747L24.9821 22.1376Z"
                      fill="#FFB65A"
                      stroke="white"
                    />
                  </svg>
                  <span>{_t("Go Back To Home")} </span>
                </a>
              </div>
              <img src="../images/logo.svg" alt="logo" />
            </Container>
          </div>
          <div>
            <Container className="progress-bar">
              <ul className="progress-ul">
                <li
                  className={`progress-li ${currentStep == 1 ? "current-progress" : ""
                    }  ${currentStep > 1 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${currentStep == 2 ? "current-progress" : ""
                    }  ${currentStep > 2 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${currentStep == 3 ? "current-progress" : ""
                    }  ${currentStep > 3 ? "current-completed" : ""}`}
                ></li>
              </ul>
            </Container>
          </div>
        </div>

        <>
          <div className="stepformauthbar-inner">
            <div className="authbar-inner">
              <div className="fullbar">
                <div className="right-contentbar">
                  {step === 1 && (
                    <div className="resend-text">
                      <h4>{_t("What is your name?")}</h4>
                      <p>{_t("Please enter your name as it appears on ID")}</p>
                      <Form className="loginform" onSubmit={handleNext}>
                        <div className="auth-form">
                          <div className="forminput row50">
                            <label className="forminput">
                              {_t("First Name")}
                            </label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="fname"
                              value={formData.fname}
                              onChange={handleInputChange}
                              required
                              placeholder={_t("Enter First Name")}
                            />
                          </div>
                          <div className="forminput row50">
                            <label className="forminput">
                              {_t("Last Name")}
                            </label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="lname"
                              value={formData.lname}
                              onChange={handleInputChange}
                              required
                              placeholder={_t("Enter Last Name")}
                            />
                          </div>
                          <div className="btngroup row100">
                            <button className="btn btnorange" type="submit">
                              {_t("Next")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}

                  {step === 2 && (
                    <div className="resend-text">
                      <h4>{_t("What are looking for?")}?</h4>
                      <p>{_t("Please select your answer")}</p>
                      <Form className="loginform" onSubmit={handleNext}>
                        <div className="auth-form">
                          <div className="forminput row100 inlinecheckboxes">
                            <div className="uilabel">
                              <label class="customradiobar">
                                <input
                                  type="radio"
                                  name="type_of_student"
                                  onChange={handleInputChange}
                                  checked={
                                    formData?.type_of_student == "Undergraduate"
                                      ? true
                                      : false
                                  }
                                  value="Undergraduate"
                                />
                                <div class="boxes">{_t("Undergraduate")}</div>
                              </label>
                            </div>
                            <div className="uilabel">
                              <label class="customradiobar">
                                <input
                                  type="radio"
                                  name="type_of_student"
                                  onChange={handleInputChange}
                                  checked={
                                    formData?.type_of_student == "Master"
                                      ? true
                                      : false
                                  }
                                  value="Master"
                                />
                                <div class="boxes">{_t("Master")}</div>
                              </label>
                            </div>
                          </div>

                          <div className="btngroup row100 gridbar">
                            <button
                              className="btn btnoutline"
                              type="button"
                              onClick={handleGoBack}
                            >
                              {_t("Go Back")}
                            </button>
                            <button
                              className="btn btnorange"
                              type="submit"
                              disabled={
                                haveValue(formData.type_of_student)
                                  ? false
                                  : true
                              }
                            >
                              {_t("Next")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                  {step === 3 && (
                    <div className="resend-text">
                      <h4>{_t("Institution Details")}</h4>

                      <p>{_t("Please enter your Details")}</p>
                      <Form className="loginform" onSubmit={handleSubmit}>
                        <div className="auth-form">
                          <div className="forminput row50">
                            <label className="forminput">
                              {_t("Education Level")}
                            </label>
                            <select
                              id="degree"
                              name="current_course"
                              value={formData.current_course}
                              onChange={handleInputChange}
                              className="form-control"
                            >
                              <option value="">
                                {_t("Select a degree")}
                              </option>
                              {formData?.type_of_student == "Undergraduate"
                                ? Object.keys(ug_courses).map((key) => (
                                  <option key={key} value={key}>
                                    {_t(key)}
                                  </option>
                                ))
                                : Object.keys(master_courses).map((key) => (
                                  <option key={key} value={key}>
                                    {_t(key)}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="forminput row50">
                            <label className="forminput">
                              {_t("Institution")}
                            </label>

                            <select
                              id="degree"
                              name="university"
                              className="form-control"
                              disabled
                            >
                              <option data-key="degree" value="Associate's">
                                IE University, Spain
                              </option>
                            </select>
                          </div>
                          <div className="forminput row100">
                            <label className="forminput ">
                              {_t("Institution Email")}
                            </label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="email_of_university"
                              value={formData.email_of_university}
                              disabled
                              placeholder={_t("Enter Your Institution Email")}
                            />
                          </div>
                          <div className="forminput row50">
                            <label className="forminput ">
                              {_t("Student ID")}
                            </label>
                            <input
                              type="text"
                              className="form-control user-auth"
                              name="ie_student_id"
                              value={formData.ie_student_id}
                              pattern="\d{5}"
                              onChange={handleInputChange}
                              maxlength="5"
                              placeholder={_t("Enter Your Student ID")}
                            />
                          </div>
                          <div className="forminput row50">
                            <label className="forminput d-flex gap-2">
                              {_t("Student registration contract")}
                              <ul className="infodorpdown scrorebardrop ">
                                <li>
                                  <button className="iconinfo d-flex align-items-center">
                                    <img src={exclamation} alt="" />
                                  </button>{" "}
                                </li>
                                <ul className="tooltip ieTooltip">
                                  <li>
                                    <PdfRender
                                      // pdfUrl={`${process.env.REACT_APP_SERVER_URL}/uploads/sampleIePdf.pdf`}
                                      pdfUrl={`/assets/sampleIePdf.pdf`}
                                    />
                                  </li>
                                </ul>
                              </ul>
                            </label>
                            <div className="file-upload-wrapper">
                              <input
                                type="file"
                                onChange={(e) => {
                                  handleFileChange(e);
                                }}
                                style={{ maxWidth: "100%" }}
                                multiple={false}
                                accept="application/pdf"
                                hidden
                                id="hiddenUploadBtn"
                              />
                              <button
                                type="button"
                                className="btn bg-none btnoutline"
                                onClick={(e) =>
                                  document
                                    .getElementById("hiddenUploadBtn")
                                    .click()
                                }
                              >
                                {_t("Choose File")}
                              </button>
                              <span id="file-name">
                                {!haveValue(fileDataState)
                                  ? _t("No file chosen")
                                  : fileDataState.target.files[0].name}
                              </span>
                            </div>
                          </div>
                          <div className="forminput row50">
                            <label className="forminput ">
                              {_t("Expected Course End Date")}
                            </label>
                            <input
                              type="date"
                              className="form-control user-auth"
                              name="university_end_date"
                              value={formData.university_end_date}
                              onChange={handleInputChange}
                              required
                            // placeholder={_t("Enter Your Institution Email")}
                            />
                          </div>
                          <div className="forminput row50">
                            <label className="forminput ">
                              {_t("Current Annual Income")}
                            </label>
                            <input
                              type="number"
                              className="form-control user-auth"
                              name="current_annual_income"
                              value={formData.current_annual_income}
                              onChange={handleInputChange}
                              placeholder={_t("Enter Income (If Applicable)")}
                            />
                          </div>
                          <div className="btngroup row100 gridbar">
                            <button
                              className="btn btnoutline"
                              type="button"
                              onClick={handleGoBack}
                            >
                              {_t("Go Back")}
                            </button>
                            <button
                              disabled={
                                haveValue(formData.current_course) &&
                                  haveValue(formData.university_end_date) &&
                                  haveValue(formData?.ie_student_id) &&
                                  haveValue(fileData) &&
                                  formData?.ie_student_id.length == 5
                                  ? false
                                  : true
                              }
                              className="btn btnorange"
                              type="submit"
                            >
                              {_t("Submit")}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>

        <Modal
          show={goHomeConfirm}
          onHide={(e) => setGoHomeConfirm(false)}
          centered
          className="newmodalui asguarantor"
        >
          <Modal.Header>
            <Modal.Title>{_t("Go Back To Home")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="forminput row100">
              {_t(
                "Warning: Continuing will result in the loss of your current information"
              )}
              .
            </div>
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline cancel-btn"
                type="button"
                onClick={(e) => setGoHomeConfirm(false)}
              >
                {_t("Cancel")}
              </button>
              <button
                className="btn btnorange"
                type="button"
                onClick={handleGoHomeConfirm}
              >
                {_t("Go Home")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Container>
          <div className="terms-bar">
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/terms-conditions.html"
              >
                {_t("Terms & Conditions")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/terms-conditions.html"
              >
                {_t("Terms & Conditions")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/privacy-policy.html"
              >
                {_t("Privacy Policy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/privacy-policy.html"
              >
                {_t("Privacy Policy")}
              </Link>
            )}
            {localStorage.getItem("i18nextLng") == "es" ? (
              <Link
                className="link-bar"
                to="https://skortorent.com/es/cookies-es.html"
              >
                {_t("Cookies Policy")}
              </Link>
            ) : (
              <Link
                className="link-bar"
                to="https://skortorent.com/cookies.html"
              >
                {_t("Cookies Policy")}
              </Link>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default StepFormUniversity;
