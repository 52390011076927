import useTranslator from "../../hooks/useTranslator";
import { Form } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { haveValue } from "../../helper/common";
import LandLordContext from "../../context/landlord/LandLordContext";
import AuthContext from "../../context/auth/AuthContext";
import { toast } from "react-toastify";

const Cadastre = (props) => {
  const {
    step,
    setStep,
    handleShow,
    formData,
    setFormData,
    handleInputChange,
    defaultState,
    handleSubmit,
    handleBackCurrent = null,
    isLandlord = false,
  } = props;

  const { _t } = useTranslator();
  const landLordContext = useContext(LandLordContext);
  const authContext = useContext(AuthContext);
  const { setLoading } = authContext;
  const { validateCadastre, getBuildingData, properties, getAutoCompleteList } =
    landLordContext;

  const [floorVals, setFloorVals] = useState([]);
  const [fromRef, setFromRef] = useState(false);
  const [autoCompleteList, setAutoCompleteList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [changeEntered, setChangeEntered] = useState(false);
  const [enteredAddress, setEnteredAddress] = useState("");
  const [doorVals, setDoorVals] = useState([]);

  useEffect(() => {
    if (!changeEntered) {
      const timeoutId = setTimeout(async () => {
        getAutoCompleteList(enteredAddress)
          .then((res) => {
            console.log(res);
            if (
              haveValue(res?.from_ref) &&
              res?.from_ref == true &&
              res?.results.length > 0
            ) {
              setFromRef(true);
              const val = res?.results[0];
              const temp_data = { ...formData };
              // const temp = {
              temp_data.street_type = val?.tipo_via;
              temp_data.street = val?.via;
              temp_data.street_number = val?.numero_via;
              temp_data.postcode = val?.cp;
              temp_data.staircase = val?.escalera;
              temp_data.floor = val?.piso;
              temp_data.door = val?.puerta;
              temp_data.municipality = val?.municipio;
              temp_data.province = val?.provincia;
              temp_data.code_ine = val?.cod_ine;
              temp_data.code_via = val?.cod_via;
              temp_data.cadastral_reference = val?.referencia_catastral;
              temp_data.area = val?.sup_util;
              // };
              setFormData(temp_data);
              setIsSelected(true);
            } else {
              setFromRef(false);
              if (haveValue(enteredAddress) && res.length == 0) {
                const not_listed = [{ address: "not_found_property" }];
                setAutoCompleteList(not_listed);
              } else {
                setAutoCompleteList(res);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [enteredAddress]);

  const handleValidateCadastre = async (e) => {
    e.preventDefault();
    if (fromRef == true) {
      handleShow();
    } else {
      if (
        haveValue(formData?.code_ine) &&
        haveValue(formData?.code_via) &&
        haveValue(formData?.street_number)
      ) {
        validateCadastre(
          formData.code_ine,
          formData?.code_via,
          formData?.street_number
        )
          .then((res) => {
            const temp_data = { ...formData };
            temp_data.street_type = res[0].tipo_via;
            temp_data.street = res[0].via;
            temp_data.postcode = res[0].cp;
            temp_data.municipality = res[0].municipio;
            temp_data.province = res[0].provincia;
            if (haveValue(res[0].referencia_catastral)) {
              temp_data.cadastral_reference = res[0].referencia_catastral;
              setFormData(temp_data);
              fetchBuildingData(res[0].referencia_catastral);
            } else {
              setFormData(temp_data);
              handleRedirectManual();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const fetchBuildingData = async (cadastral_reference) => {
    getBuildingData(
      cadastral_reference,
      formData.code_via,
      formData.street_number
    )
      .then((res) => {
        console.log(res);
        if (res.length == 1 && !haveValue(res[0].vertical)) {
          setFormData((prev) => ({ ...prev, area: res[0].sup_util }));
          setStep(3);
        } else if (res.length == 0) {
          setStep(3);
        } else {
          const highestFloor = [
            ...new Set(res.map((item) => item.piso)),
          ].sort();
          const highestLetter = {};
          console.log(res);
          res.forEach((item) => {
            const piso = item.piso;
            const puerta = item.puerta;

            if (!(piso in highestLetter)) {
              highestLetter[piso] = [];
            }
            highestLetter[piso][`${puerta}`] = item;
          });

          setFloorVals(highestFloor);
          setDoorVals(highestLetter);

          handleNext();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addVals = () => {
    setLoading(true);
    console.log(properties, "propertiesproperties");
    const check = properties.filter(
      (prop) =>
        prop?.street == `${formData?.street_type}, ${formData?.street}` &&
        prop?.street_number == formData?.street_number &&
        prop?.floor_number == formData?.floor &&
        prop?.flat_number == formData?.door
    );

    if (check.length > 0) {
      toast.error(_t("Property Already Exists!"));
    } else {
      const current_selected = doorVals[formData.floor][formData.door];
      setFormData((prev) => ({
        ...prev,
        area: current_selected.sup_util,
        staircase: current_selected.escalera,
      }));
      handleShow();
    }
    setLoading(false);
  };

  const handleFloorInput = async (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      area: doorVals[formData?.floor][formData?.door].sup_util,
    }));
  };

  const handleSelectAddr = async (e) => {
    setEnteredAddress(e.target.textContent);
    const code_ine = e.target.getAttribute("data-cod-ine");
    const code_via = e.target.getAttribute("data-cod-via");
    const entered_address = e.target.getAttribute("data-entered-address");
    setEnteredAddress(entered_address);
    setChangeEntered(true);
    const street_number = haveValue(e.target.getAttribute("data-numero-via"))
      ? e.target.getAttribute("data-numero-via")
      : "";
    setFormData((prevData) => ({
      ...prevData,
      ...defaultState,
      code_ine,
      code_via,
      street_number,
    }));
    setIsSelected(true);
  };

  const handleAddressType = (e) => {
    setChangeEntered(false);
    setEnteredAddress(e.target.value);
    setFormData((prevData) => ({ ...prevData, code_ine: "", code_via: "" }));
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleGoBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleRedirectManual = () => {
    setStep(3);
  };

  return (
    <>
      <div className="right-contentbar">
        {step === 1 && (
          <div className={` steps-wrapper ${isLandlord && "resend-text"}`}>
            <h4 className="step-name">{_t("What is your address?")}</h4>

            <Form className="loginform" onSubmit={handleValidateCadastre}>
              <div className="auth-form">
                <div className="row m-0 align-items-end">
                  <div className="col-md-8">
                    <div className="forminput ">
                      <label className="forminput label-text">
                        {_t("Street or reference")}
                      </label>
                      <input
                        type="text"
                        className="form-control user-auth"
                        name="entedAddress"
                        value={enteredAddress}
                        onChange={handleAddressType}
                        onKeyDown={(e) => setIsSelected(false)}
                        required
                        placeholder={_t("Enter Street Or Reference")}
                      />
                      {haveValue(autoCompleteList) &&
                        autoCompleteList.length > 0 &&
                        haveValue(enteredAddress) &&
                        isSelected == false && (
                          <ul className="autocomplete_ul">
                            {autoCompleteList.map((ele, index) =>
                              ele.address == "not_found_property" ? (
                                <li
                                  key={index}
                                  className="autocomplete_li"
                                  onClick={handleRedirectManual}
                                >
                                  {"My House Is Not Listed"}
                                </li>
                              ) : (
                                <li
                                  key={index}
                                  className="autocomplete_li"
                                  data-entered-address={`${ele?.tipo_via} ${ele?.via}, ${ele?.address_2}`}
                                  data-numero-via={
                                    haveValue(ele?.numero_via)
                                      ? ele?.numero_via
                                      : null
                                  }
                                  data-cod-ine={ele.cod_ine}
                                  data-cod-via={ele.cod_via}
                                  onClick={handleSelectAddr}
                                >
                                  {ele.address}
                                </li>
                              )
                            )}
                          </ul>
                        )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="forminput ">
                      <label className="forminput label-text">
                        {_t("Number")}
                      </label>
                      <input
                        type="text"
                        className="form-control user-auth"
                        name="street_number"
                        value={formData.street_number}
                        onChange={handleInputChange}
                        required
                        placeholder={_t("Enter Number")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center btngroup gap-3 step-btns-group">
                      {haveValue(handleBackCurrent) && (
                        <button
                          className="btn btnoutline"
                          onClick={handleBackCurrent}
                        >
                          {_t("Go Back")}
                        </button>
                      )}
                      <button
                        className="btn btnorange step-btn"
                        type="submit"
                        disabled={
                          !haveValue(formData?.code_ine) ||
                          !haveValue(formData.street_number)
                        }
                      >
                        {_t("Next")}
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 14 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.52422 11.748C7.44592 11.6684 7.3838 11.5738 7.34141 11.4696C7.29902 11.3655 7.2772 11.2538 7.2772 11.141C7.2772 11.0282 7.29902 10.9166 7.34141 10.8124C7.3838 10.7083 7.44592 10.6137 7.52422 10.534L11.1278 6.85729L0.839835 6.85729C0.617097 6.85729 0.403482 6.76701 0.245983 6.60631C0.0884835 6.44562 6.81454e-07 6.22766 6.91388e-07 6.0004C7.01322e-07 5.77314 0.0884836 5.55519 0.245983 5.39449C0.403482 5.2338 0.617097 5.14352 0.839835 5.14352L11.1278 5.14352L7.52422 1.46533C7.36644 1.30436 7.27781 1.08603 7.27781 0.858373C7.27781 0.630718 7.36644 0.412387 7.52422 0.251411C7.68199 0.0904353 7.89597 -2.64417e-07 8.1191 -2.57062e-07C8.34222 -2.49708e-07 8.55621 0.0904353 8.71398 0.251411L13.753 5.39273C13.8313 5.47234 13.8934 5.56693 13.9358 5.67109C13.9782 5.77524 14 5.88691 14 5.99969C14 6.11247 13.9782 6.22414 13.9358 6.32829C13.8934 6.43245 13.8313 6.52704 13.753 6.60665L8.71398 11.748C8.63596 11.8279 8.54324 11.8912 8.44116 11.9345C8.33908 11.9777 8.22963 12 8.1191 12C8.00856 12 7.89912 11.9777 7.79703 11.9345C7.69495 11.8912 7.60224 11.8279 7.52422 11.748Z"
                            fill="#253837"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
        {step === 2 && (
          <div className={` steps-wrapper ${isLandlord && "resend-text"}`}>
            <h4 className="step-name">{_t("What is your address?")}</h4>

            <Form className="loginform" onSubmit={handleFloorInput}>
              <div className="auth-form">
                <div className="row w-100 m-0">
                  <div className="col-md-8">
                    <div className="forminput ">
                      <label className="forminput label-text">
                        {_t("Street or reference")}
                      </label>
                      <input
                        type="text"
                        className="form-control user-auth"
                        name="entedAddress"
                        value={enteredAddress}
                        disabled
                        placeholder={_t("Enter Street Or Reference")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="forminput ">
                      <label className="forminput label-text">
                        {_t("Number")}
                      </label>
                      <input
                        type="text"
                        className="form-control user-auth"
                        name="street_number"
                        value={formData.street_number}
                        disabled
                        placeholder={_t("Enter Number")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row w-100 m-0">
                  <div className="col-md-6">
                    <div className="forminput ">
                      <label className="forminput label-text">
                        {_t("Floor Number")}
                      </label>
                      <select
                        className="form-control"
                        name="floor"
                        id="floor_val"
                        value={formData.floor}
                        onChange={(e) => {
                          handleInputChange(e);
                          setFormData((prev) => ({
                            ...prev,
                            door: "",
                          }));
                        }}
                      >
                        <option value="">{_t("Select")}</option>
                        {floorVals.map((floor) => (
                          <option value={floor}>{floor}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="forminput ">
                      <label className="forminput label-text">
                        {_t("Letter")}
                      </label>
                      <select
                        className="form-control"
                        name="door"
                        id="door_val"
                        value={formData.door}
                        onChange={handleInputChange}
                      >
                        <option value="">{_t("Select")}</option>
                        {haveValue(formData?.floor) &&
                          // console.log(doorVals[formData?.floor]['A'])
                          Object.keys(doorVals[formData?.floor]).map((door) => (
                            <option value={door}>{door}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="forminput row100 text-center">
                  <a
                    href={undefined}
                    className="setNoInfoColor"
                    onClick={handleRedirectManual}
                  >
                    {_t("Can't Find Your Address")}?
                  </a>
                </div>
                <div className="btngroup row100 gridbar">
                  <button
                    className="btn btnoutline"
                    type="button"
                    onClick={handleGoBack}
                  >
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.47578 11.748C6.55408 11.6684 6.6162 11.5738 6.65859 11.4696C6.70098 11.3655 6.7228 11.2538 6.7228 11.141C6.7228 11.0282 6.70098 10.9166 6.65859 10.8124C6.6162 10.7083 6.55408 10.6137 6.47578 10.534L2.8722 6.85729L13.1602 6.85729C13.3829 6.85729 13.5965 6.76701 13.754 6.60631C13.9115 6.44562 14 6.22766 14 6.0004C14 5.77314 13.9115 5.55519 13.754 5.39449C13.5965 5.2338 13.3829 5.14352 13.1602 5.14352L2.8722 5.14352L6.47578 1.46533C6.63356 1.30436 6.72219 1.08603 6.72219 0.858373C6.72219 0.630718 6.63356 0.412387 6.47578 0.251411C6.31801 0.0904353 6.10403 -2.64417e-07 5.8809 -2.57062e-07C5.65778 -2.49708e-07 5.44379 0.0904353 5.28602 0.251411L0.247014 5.39273C0.168719 5.47234 0.106596 5.56693 0.0642081 5.67109C0.02182 5.77524 2.57325e-07 5.88691 2.62255e-07 5.99969C2.67185e-07 6.11247 0.02182 6.22414 0.0642082 6.32829C0.106596 6.43245 0.168719 6.52704 0.247014 6.60665L5.28602 11.748C5.36404 11.8279 5.45676 11.8912 5.55884 11.9345C5.66092 11.9777 5.77037 12 5.8809 12C5.99144 12 6.10088 11.9777 6.20297 11.9345C6.30505 11.8912 6.39776 11.8279 6.47578 11.748Z"
                        fill="#223635"
                      />
                    </svg>
                    {_t("Go Back")}
                  </button>
                  <button
                    className="btn btngreen"
                    type="submit"
                    onClick={addVals}
                    disabled={
                      !haveValue(formData?.floor) || !haveValue(formData?.door)
                    }
                  >
                    {_t("Find Property")}
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.52422 11.748C7.44592 11.6684 7.3838 11.5738 7.34141 11.4696C7.29902 11.3655 7.2772 11.2538 7.2772 11.141C7.2772 11.0282 7.29902 10.9166 7.34141 10.8124C7.3838 10.7083 7.44592 10.6137 7.52422 10.534L11.1278 6.85729L0.839835 6.85729C0.617097 6.85729 0.403482 6.76701 0.245983 6.60631C0.0884835 6.44562 6.81454e-07 6.22766 6.91388e-07 6.0004C7.01322e-07 5.77314 0.0884836 5.55519 0.245983 5.39449C0.403482 5.2338 0.617097 5.14352 0.839835 5.14352L11.1278 5.14352L7.52422 1.46533C7.36644 1.30436 7.27781 1.08603 7.27781 0.858373C7.27781 0.630718 7.36644 0.412387 7.52422 0.251411C7.68199 0.0904353 7.89597 -2.64417e-07 8.1191 -2.57062e-07C8.34222 -2.49708e-07 8.55621 0.0904353 8.71398 0.251411L13.753 5.39273C13.8313 5.47234 13.8934 5.56693 13.9358 5.67109C13.9782 5.77524 14 5.88691 14 5.99969C14 6.11247 13.9782 6.22414 13.9358 6.32829C13.8934 6.43245 13.8313 6.52704 13.753 6.60665L8.71398 11.748C8.63596 11.8279 8.54324 11.8912 8.44116 11.9345C8.33908 11.9777 8.22963 12 8.1191 12C8.00856 12 7.89912 11.9777 7.79703 11.9345C7.69495 11.8912 7.60224 11.8279 7.52422 11.748Z"
                        fill="#253837"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
        {step === 3 && (
          <div className={` steps-wrapper ${isLandlord && "resend-text"}`}>
            <h4 className="step-name">{_t("What is your address?")}</h4>

            {/* <Form className="loginform" onSubmit={handleNext}> */}
            <Form className="loginform" onSubmit={handleSubmit}>
              <div className="auth-form">
                <div className="forminput row100">
                  <label className="forminput label-text">
                    {_t("Street Type")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="street_type"
                    value={formData?.street_type}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Street Type")}
                  />
                </div>
                <div className="forminput row50">
                  <label className="forminput label-text">{_t("Street")}</label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="street"
                    value={formData?.street}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Street")}
                  />
                </div>
                <div className="forminput row50">
                  <label className="forminput label-text">{_t("Number")}</label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="street_number"
                    value={formData?.street_number}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Number")}
                  />
                </div>
                <div className="forminput row50">
                  <label className="forminput label-text">
                    {_t("Post Code")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="postcode"
                    value={formData?.postcode}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Post Code")}
                  />
                </div>
                <div className="forminput row50">
                  <label className="forminput label-text">
                    {_t("Stairway")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="staircase"
                    value={formData?.staircase}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Stairway")}
                  />
                </div>
                <div className="forminput row50">
                  <label className="forminput label-text">
                    {_t("Floor Number")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="floor"
                    value={formData?.floor}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Floor Number")}
                  />
                </div>
                <div className="forminput row50">
                  <label className="forminput label-text">{_t("Letter")}</label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="door"
                    value={formData?.door}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Letter")}
                  />
                </div>
                <div className="forminput row100">
                  <label className="forminput label-text">
                    {_t("Municipality")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="municipality"
                    value={formData?.municipality}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Municipality")}
                  />
                </div>
                <div className="forminput row100">
                  <label className="forminput label-text">
                    {_t("Province")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="province"
                    value={formData?.province}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Province")}
                  />
                </div>
                <div className="forminput row100">
                  <label className="forminput label-text">
                    {_t("Property Reference (Optional)")}
                  </label>
                  <input
                    className="form-control user-auth"
                    name="property_reference"
                    value={formData?.property_reference}
                    onChange={handleInputChange}
                    placeholder={_t("Enter Property Reference")}
                  />
                </div>
                <div className="btngroup row100 gridbar">
                  <button
                    className="btn btnoutline"
                    type="button"
                    onClick={(e) => setStep(1)}
                  >
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.47578 11.748C6.55408 11.6684 6.6162 11.5738 6.65859 11.4696C6.70098 11.3655 6.7228 11.2538 6.7228 11.141C6.7228 11.0282 6.70098 10.9166 6.65859 10.8124C6.6162 10.7083 6.55408 10.6137 6.47578 10.534L2.8722 6.85729L13.1602 6.85729C13.3829 6.85729 13.5965 6.76701 13.754 6.60631C13.9115 6.44562 14 6.22766 14 6.0004C14 5.77314 13.9115 5.55519 13.754 5.39449C13.5965 5.2338 13.3829 5.14352 13.1602 5.14352L2.8722 5.14352L6.47578 1.46533C6.63356 1.30436 6.72219 1.08603 6.72219 0.858373C6.72219 0.630718 6.63356 0.412387 6.47578 0.251411C6.31801 0.0904353 6.10403 -2.64417e-07 5.8809 -2.57062e-07C5.65778 -2.49708e-07 5.44379 0.0904353 5.28602 0.251411L0.247014 5.39273C0.168719 5.47234 0.106596 5.56693 0.0642081 5.67109C0.02182 5.77524 2.57325e-07 5.88691 2.62255e-07 5.99969C2.67185e-07 6.11247 0.02182 6.22414 0.0642082 6.32829C0.106596 6.43245 0.168719 6.52704 0.247014 6.60665L5.28602 11.748C5.36404 11.8279 5.45676 11.8912 5.55884 11.9345C5.66092 11.9777 5.77037 12 5.8809 12C5.99144 12 6.10088 11.9777 6.20297 11.9345C6.30505 11.8912 6.39776 11.8279 6.47578 11.748Z"
                        fill="#223635"
                      />
                    </svg>
                    {_t("Go Back")}
                  </button>
                  {/* <button className="btn btngreen" type="submit">
                    {_t("Next")}
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.52422 11.748C7.44592 11.6684 7.3838 11.5738 7.34141 11.4696C7.29902 11.3655 7.2772 11.2538 7.2772 11.141C7.2772 11.0282 7.29902 10.9166 7.34141 10.8124C7.3838 10.7083 7.44592 10.6137 7.52422 10.534L11.1278 6.85729L0.839835 6.85729C0.617097 6.85729 0.403482 6.76701 0.245983 6.60631C0.0884835 6.44562 6.81454e-07 6.22766 6.91388e-07 6.0004C7.01322e-07 5.77314 0.0884836 5.55519 0.245983 5.39449C0.403482 5.2338 0.617097 5.14352 0.839835 5.14352L11.1278 5.14352L7.52422 1.46533C7.36644 1.30436 7.27781 1.08603 7.27781 0.858373C7.27781 0.630718 7.36644 0.412387 7.52422 0.251411C7.68199 0.0904353 7.89597 -2.64417e-07 8.1191 -2.57062e-07C8.34222 -2.49708e-07 8.55621 0.0904353 8.71398 0.251411L13.753 5.39273C13.8313 5.47234 13.8934 5.56693 13.9358 5.67109C13.9782 5.77524 14 5.88691 14 5.99969C14 6.11247 13.9782 6.22414 13.9358 6.32829C13.8934 6.43245 13.8313 6.52704 13.753 6.60665L8.71398 11.748C8.63596 11.8279 8.54324 11.8912 8.44116 11.9345C8.33908 11.9777 8.22963 12 8.1191 12C8.00856 12 7.89912 11.9777 7.79703 11.9345C7.69495 11.8912 7.60224 11.8279 7.52422 11.748Z"
                        fill="#253837"
                      />
                    </svg>
                  </button> */}
                  <button className="btn btngreen" type="submit">
                    {_t("Submit")}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
        {step === 4 && (
          <div className={` steps-wrapper ${isLandlord && "resend-text"}`}>
            <h4>{_t("Municipality & Province")}?</h4>

            <Form className="loginform" onSubmit={handleSubmit}>
              <div className="auth-form">
                <div className="forminput row100">
                  <label className="forminput label-text">
                    {_t("Municipality")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="municipality"
                    value={formData?.municipality}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Municipality")}
                  />
                </div>
                <div className="forminput row100">
                  <label className="forminput label-text">
                    {_t("Province")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="province"
                    value={formData?.province}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Province")}
                  />
                </div>
                <div className="btngroup row100 gridbar">
                  <button
                    className="btn btnoutline"
                    type="button"
                    onClick={handleGoBack}
                  >
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.47578 11.748C6.55408 11.6684 6.6162 11.5738 6.65859 11.4696C6.70098 11.3655 6.7228 11.2538 6.7228 11.141C6.7228 11.0282 6.70098 10.9166 6.65859 10.8124C6.6162 10.7083 6.55408 10.6137 6.47578 10.534L2.8722 6.85729L13.1602 6.85729C13.3829 6.85729 13.5965 6.76701 13.754 6.60631C13.9115 6.44562 14 6.22766 14 6.0004C14 5.77314 13.9115 5.55519 13.754 5.39449C13.5965 5.2338 13.3829 5.14352 13.1602 5.14352L2.8722 5.14352L6.47578 1.46533C6.63356 1.30436 6.72219 1.08603 6.72219 0.858373C6.72219 0.630718 6.63356 0.412387 6.47578 0.251411C6.31801 0.0904353 6.10403 -2.64417e-07 5.8809 -2.57062e-07C5.65778 -2.49708e-07 5.44379 0.0904353 5.28602 0.251411L0.247014 5.39273C0.168719 5.47234 0.106596 5.56693 0.0642081 5.67109C0.02182 5.77524 2.57325e-07 5.88691 2.62255e-07 5.99969C2.67185e-07 6.11247 0.02182 6.22414 0.0642082 6.32829C0.106596 6.43245 0.168719 6.52704 0.247014 6.60665L5.28602 11.748C5.36404 11.8279 5.45676 11.8912 5.55884 11.9345C5.66092 11.9777 5.77037 12 5.8809 12C5.99144 12 6.10088 11.9777 6.20297 11.9345C6.30505 11.8912 6.39776 11.8279 6.47578 11.748Z"
                        fill="#223635"
                      />
                    </svg>
                    {_t("Go Back")}
                  </button>
                  <button className="btn btngreen" type="submit">
                    {_t("Submit")}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
        {step === 5 && (
          <div
            className={` steps-wrapper ${isLandlord && "resend-text"}`}
          ></div>
        )}
      </div>
    </>
  );
};

export default Cadastre;
