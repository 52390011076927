import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import LandLordContext from "../../../context/landlord/LandLordContext";
import AuthContext from "../../../context/auth/AuthContext";
import { useSnackbar } from "material-ui-snackbar-provider";

import editicon from "../../../assets/img/edit.png";
import share1 from "../../../assets/img/share1.png";
import delicon from "../../../assets/img/del.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTranslator from "../../../hooks/useTranslator";
import {
  convertCurrencyFormat,
  haveValue,
  getBaseUrl,
} from "../../../helper/common";
import { Modal } from "react-bootstrap";
const SingleProperty = ({ property, number, onPropertyAward }) => {
  const { _t } = useTranslator();

  const [show, setShow] = useState(false);
  const [detailsShow, setDetailsShow] = useState(false);
  const [propData, setPropData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [deleteId, setDeleteId] = useState("");

  const showmodal = (propId) => {
    setDeleteId(propId);
    handleShow();
  };

  const snackbar = useSnackbar();

  const {
    floor_number,
    street_number,
    street,
    flat_number,
    postcode,
    muncipality,
    province,
    staircase,
    area,
    property_reference = null,
  } = property;
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const navigate = useNavigate();
  const landLordContext = useContext(LandLordContext);
  const {
    loadingLandlord,
    demoFunLandlord,
    setCurrentProperty,
    deleteProperty,
    updatePropertyDetails,
  } = landLordContext;

  const [awardConfirmationModal, setAwardConfirmationModal] = useState(false);

  const editProperty = (propertyid, property) => {
    console.log("this property has to be edited", propertyid);

    console.log(property);
    setCurrentProperty(property);
    //navigate('/edit-property');
  };

  const deleteMyProperty = (propertyid) => {
    console.log("this property has to be deleted", propertyid);
    deleteProperty(propertyid, user?._id);
  };

  const shareLink = (propertyid) => {
    let landlordid = localStorage.getItem("saveduserid");
    let sharing_url = `http://localhost:3000/propshare?propid=${propertyid}&landlordid=${landlordid}`;
    let sharing_url_live = `https://${process.env.REACT_APP_URL_NAME}/propshare?propid=${propertyid}&landlordid=${landlordid}`;
    // navigator.clipboard.writeText(sharing_url);
    navigator.clipboard.writeText(sharing_url_live);
    snackbar.showMessage(_t("Sharing Link has been copied"));
  };

  const handleOpenDetails = (property) => {
    setPropData(property);
    setDetailsShow(true);
  };

  const checkSalary = (user) => {
    if (haveValue(user?.gurantor_id)) {
      if (user?.gurantor_id?.algoanIncome) {
        let monthlyIncome = parseInt(
          user?.gurantor_id?.algoanIncome?.monthlyAmount
        );
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user?.gurantor_id?.current_annual_income)
            ? user?.gurantor_id?.current_annual_income
            : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        return {
          matched: salaryMatch,
          salary: recorded_annual_salary,
        };
      }
    } else {
      if (user?.algoanIncome) {
        let monthlyIncome = parseInt(user?.algoanIncome?.monthlyAmount);
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user.current_annual_income) ? user.current_annual_income : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        return {
          matched: salaryMatch,
          salary: recorded_annual_salary,
        };
      }
    }
  };

  const calculateRentAmount = (tenantData) => {
    if (
      haveValue(tenantData?.rent_from_income) &&
      haveValue(tenantData?.rent_percentage)
    ) {
      if (tenantData?.rent_from_income == "current_annual_income") {
        return (
          convertCurrencyFormat(
            Math.floor(
              (tenantData?.tenantid?.current_annual_income || 0) *
              tenantData?.rent_percentage
            ) / 1200
          ) || 0
        );
      } else if (tenantData?.rent_from_income == "itr_amount") {
        return (
          convertCurrencyFormat(
            Math.floor(
              (tenantData?.tenantid?.itr_amount || 0) *
              tenantData?.rent_percentage
            ) / 1200
          ) || 0
        );
      } else if (tenantData?.rent_from_income == "recordedSalary") {
        const recordedSal = checkSalary(tenantData?.tenantid);
        return (
          convertCurrencyFormat(
            Math.floor(
              ((recordedSal?.salary || 0) * tenantData?.rent_percentage) / 1200
            )
          ) || 0
        );
      }
    } else {
      const doc_type = user?.rent_from || "current_annual_income";
      const rentPercentage = user?.rent_percentage;
      if (doc_type == "current_annual_income") {
        return (
          convertCurrencyFormat(
            Math.floor(
              ((tenantData?.tenantid?.current_annual_income || 0) *
                rentPercentage) /
              1200
            )
          ) || 0
        );
      } else if (doc_type == "itr_amount") {
        return (
          convertCurrencyFormat(
            Math.floor(
              ((tenantData?.tenantid?.itr_amount || 0) * rentPercentage) / 1200
            )
          ) || 0
        );
      } else if (doc_type == "recordedSalary") {
        const recordedSal = checkSalary(tenantData?.tenantid);
        return (
          convertCurrencyFormat(
            Math.floor(((recordedSal?.salary || 0) * rentPercentage) / 1200)
          ) || 0
        );
      }
    }
  };

  const handleNavigateProfile = (assignedProp) => {
    navigate(
      `/profile-property?sharedprofileid=${assignedProp?._id}&step=linked_props`
    );
  };

  const awardPropertyHandler = async () => {
    try {
      let propertyId = property._id.toString();
      await updatePropertyDetails(propertyId, {
        is_awarded: property.is_awarded === true ? false : true,
      });

      onPropertyAward(propertyId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="col-lg-12 col-xl-6">
        <div className="properties-box">
          <div className="flex-row">
            {/* <h4>{_t("Property")} {number + 1}</h4> */}
            <h4>
              {street} - {street_number}
            </h4>
            <div>
              <Link onClick={() => showmodal(property._id)}>
                <img src={delicon} alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-content">
            <ul>
              <li>
                <span>{_t("Street")}:</span>
                <span>{_t("Number")}:</span>
              </li>
              <li>
                <span
                  className={`cl-text ${haveValue(street) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(street) ? street : _t("No Info")}
                </span>
                <span
                  className={`cl-text ${haveValue(street_number) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(street_number) ? street_number : _t("No Info")}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-content">
            <ul>
              <li>
                <span>{_t("Stairway")}:</span>
                <span>{_t("Floor number")}:</span>
              </li>
              <li>
                <span
                  className={`cl-text ${haveValue(staircase) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(staircase) ? staircase : _t("No Info")}
                </span>
                <span
                  className={`cl-text ${haveValue(floor_number) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(floor_number) ? floor_number : _t("No Info")}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-content">
            <ul>
              <li>
                <span>{_t("Letter")}:</span>
                <span>{_t("Municipality")}:</span>
              </li>
              <li>
                <span
                  className={`cl-text ${haveValue(flat_number) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(flat_number) ? flat_number : _t("No Info")}
                </span>
                <span
                  className={`cl-text ${haveValue(muncipality) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(muncipality) ? muncipality : _t("No Info")}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-content">
            <ul>
              <li>
                <span>{_t("Post Code")}:</span>
                <span>{_t("Province")}</span>
              </li>
              <li>
                <span
                  className={`cl-text ${haveValue(postcode) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(postcode) ? postcode : _t("No Info")}
                </span>
                <span
                  className={`cl-text ${haveValue(province) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(province) ? province : _t("No Info")}
                </span>
              </li>
            </ul>
          </div>
          <div className="flex-content d-flex justify-content-between align-items-start">
            <ul className="col-6">
              <li>
                <span>{_t("Area")}:</span>
                <span>&nbsp;</span>
              </li>
              <li>
                <span
                  className={`cl-text ${haveValue(area) ? "" : "setNoInfoText"
                    }`}
                >
                  {haveValue(area) ? area : _t("No Info")}
                </span>
                <span>&nbsp;</span>
              </li>
            </ul>
            {
              user?._id.toString() ==
              process.env.REACT_APP_MACC_LANDLORED_ID &&
              <div className=" col-6">
                {
                  (!property?.hasOwnProperty("is_awarded") ||
                    property?.is_awarded === false) && (
                    <button
                      className="mb-2 awarded-btn"
                      type="button"
                      onClick={() => { setAwardConfirmationModal(true) }}
                    >
                      {_t("Mark as Awarded")}
                    </button>
                  )}
                {(!property?.hasOwnProperty("is_awarded") ||
                  property?.is_awarded === true) &&
                  <button
                    className="mb-2 awarded-btn btn-gray"
                    type="button"
                    onClick={() => { setAwardConfirmationModal(true) }}
                  >
                    {/* <img
                    style={{ width: "1vw" }}
                    src="/images/Tick.svg"
                    alt=""
                  /> */}
                    {_t("Unmark as Awarded")}
                  </button>
                }
              </div>
            }
          </div>

          {haveValue(property_reference) && (
            <div className="flex-content">
              <ul>
                <li className="property_details_100">
                  <span>{_t("Property Reference")}:</span>
                </li>
                <li className="property_details_100">
                  <span
                    className={`cl-text ${haveValue(property_reference) ? "" : "setNoInfoText"
                      }`}
                  >
                    {haveValue(property_reference)
                      ? property_reference
                      : _t("No Info")}
                  </span>
                </li>
              </ul>
            </div>
          )}
          <div
            className="d-flex justify-content-between linked_tenant_div"
            onClick={(e) => handleOpenDetails(property)}
          >
            <div className="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
              >
                <path
                  d="M13.5 4.21053C13.5 5.32723 13.0259 6.39819 12.182 7.18782C11.3381 7.97745 10.1935 8.42105 9 8.42105C7.80653 8.42105 6.66193 7.97745 5.81802 7.18782C4.97411 6.39819 4.5 5.32723 4.5 4.21053C4.5 3.09383 4.97411 2.02286 5.81802 1.23323C6.66193 0.443608 7.80653 0 9 0C10.1935 0 11.3381 0.443608 12.182 1.23323C13.0259 2.02286 13.5 3.09383 13.5 4.21053ZM13.1378 11.0558C12.6056 11.0611 12.1095 11.08 11.6775 11.1337C10.9541 11.2253 10.1621 11.4421 9.5085 12.0547C8.85375 12.6663 8.622 13.4074 8.52525 14.0842C8.4375 14.6926 8.4375 15.4358 8.4375 16.2253V16.4063C8.4375 17.1958 8.4375 17.9389 8.52525 18.5474C8.59275 19.0221 8.72775 19.5284 9.02812 20H9C0 20 0 17.8789 0 15.2632C0 12.6474 4.02975 10.5263 9 10.5263C10.4917 10.5263 11.8991 10.7168 13.1378 11.0558Z"
                  fill="#253837"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.0625 20C12.2062 20 11.2781 20 10.7021 19.46C10.125 18.9211 10.125 18.0526 10.125 16.3158C10.125 14.579 10.125 13.7105 10.7021 13.1716C11.2781 12.6316 12.2062 12.6316 14.0625 12.6316C15.9187 12.6316 16.8469 12.6316 17.4229 13.1716C18 13.7105 18 14.579 18 16.3158C18 18.0526 18 18.9211 17.4229 19.46C16.8469 20 15.9187 20 14.0625 20ZM16.2765 15.5221C16.3996 15.407 16.4687 15.2508 16.4687 15.0879C16.4687 14.925 16.3996 14.7689 16.2765 14.6537C16.1534 14.5385 15.9865 14.4738 15.8124 14.4738C15.6384 14.4738 15.4715 14.5385 15.3484 14.6537L13.1884 16.6747L12.7766 16.2905C12.7157 16.2335 12.6433 16.1883 12.5637 16.1574C12.4841 16.1266 12.3987 16.1107 12.3126 16.1107C12.2264 16.1107 12.141 16.1266 12.0614 16.1574C11.9818 16.1883 11.9094 16.2335 11.8485 16.2905C11.7876 16.3476 11.7392 16.4153 11.7062 16.4898C11.6733 16.5643 11.6563 16.6441 11.6563 16.7248C11.6563 16.8054 11.6733 16.8852 11.7062 16.9597C11.7392 17.0342 11.7876 17.1019 11.8485 17.159L12.7238 17.9779C12.7847 18.035 12.857 18.0802 12.9366 18.1111C13.0163 18.142 13.1016 18.1579 13.1878 18.1579C13.274 18.1579 13.3594 18.142 13.439 18.1111C13.5186 18.0802 13.591 18.035 13.6519 17.9779L16.2765 15.5221Z"
                  fill="#253837"
                />
              </svg>
              <p className="linked_p">
                {_t("Linked to:")} {property.sharedProfiles.length || 0}
              </p>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
              >
                <path
                  d="M1 14L8 7.5L1 1"
                  stroke="#121212"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} className="metermodal" centered onHide={handleClose}>
        <Modal.Body>
          {/* <h4>{_t("Your House Size")} {formData?.area} {_t("Meters, Is That Correct")}?</h4> */}
          <h4>
            {_t(
              "Are you sure you want to delete this property? Please note that this action cannot be undone."
            )}
          </h4>
          <div className="auth-form">
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={(e) => {
                  handleClose();
                }}
              >
                {_t("No")}
              </button>
              <button
                className="btn btngreen"
                onClick={(e) => deleteMyProperty(deleteId)}
              >
                {_t("Yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={detailsShow}
        className="metermodal modal-xl"
        centered
        onHide={(e) => {
          setDetailsShow(false);
          setPropData({});
        }}
      >
        <Modal.Body>
          <div className="d-flex justify-content-between assigned_details_modal">
            <div className="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.7068 0.29279L19.7051 9.29291C20.335 9.92291 19.889 10.9999 18.9982 10.9999H17.9984V16.9999C17.9984 17.7956 17.6824 18.5587 17.1199 19.1213C16.5574 19.6839 15.7945 20 14.9989 20H13.9992V12.9999C13.9992 12.2347 13.7069 11.4984 13.182 10.9416C12.6572 10.3849 11.9394 10.0498 11.1756 10.0049L10.9997 9.99988H9.00005C8.20458 9.99988 7.44164 10.3159 6.87917 10.8786C6.31664 11.4412 6.00064 12.2042 6.00064 12.9999V20H5.00082C4.20531 20 3.4424 19.6839 2.87989 19.1213C2.31739 18.5587 2.00137 17.7956 2.00137 16.9999V10.9999H1.00156C0.111727 10.9999 -0.33519 9.92291 0.294693 9.29291L9.29299 0.29279C9.48052 0.105316 9.73476 0 9.99987 0C10.265 0 10.5193 0.105316 10.7068 0.29279ZM10.9997 11.9999C11.2649 11.9999 11.5192 12.1053 11.7067 12.2928C11.8942 12.4804 11.9995 12.7347 11.9995 12.9999V20H8.00029V12.9999C8.00029 12.755 8.09017 12.5186 8.25293 12.3356C8.41564 12.1525 8.63988 12.0356 8.88311 12.0069L9.00005 11.9999H10.9997Z"
                  fill="#253837"
                />
              </svg>
              <p className="assigned_address">
                {street} - {street_number}
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setDetailsShow(false);
                setPropData({});
              }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7734 1.32064C16.8453 1.24881 16.9022 1.16352 16.9411 1.06966C16.98 0.975805 17 0.875207 17 0.773615C17 0.672022 16.98 0.571424 16.9411 0.477565C16.9022 0.383706 16.8453 0.298423 16.7734 0.226586C16.7016 0.15475 16.6163 0.0977657 16.5224 0.058888C16.4286 0.0200102 16.328 0 16.2264 0C16.1248 0 16.0242 0.0200102 15.9303 0.058888C15.8365 0.0977657 15.7512 0.15475 15.6794 0.226586L8.5 7.40749L1.32064 0.226586C1.24881 0.15475 1.16352 0.0977657 1.06966 0.058888C0.975805 0.0200102 0.875207 7.56923e-10 0.773615 0C0.672022 -7.56923e-10 0.571424 0.0200102 0.477565 0.058888C0.383706 0.0977657 0.298423 0.15475 0.226586 0.226586C0.15475 0.298423 0.0977657 0.383706 0.058888 0.477565C0.0200102 0.571424 -7.56923e-10 0.672022 0 0.773615C7.56923e-10 0.875207 0.0200102 0.975805 0.058888 1.06966C0.0977657 1.16352 0.15475 1.24881 0.226586 1.32064L7.40749 8.5L0.226586 15.6794C0.0815054 15.8244 0 16.0212 0 16.2264C0 16.4316 0.0815054 16.6283 0.226586 16.7734C0.371667 16.9185 0.568439 17 0.773615 17C0.97879 17 1.17556 16.9185 1.32064 16.7734L8.5 9.59251L15.6794 16.7734C15.8244 16.9185 16.0212 17 16.2264 17C16.4316 17 16.6283 16.9185 16.7734 16.7734C16.9185 16.6283 17 16.4316 17 16.2264C17 16.0212 16.9185 15.8244 16.7734 15.6794L9.59251 8.5L16.7734 1.32064Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="assigned_details_body">
            <p className="linked_p_details">
              {_t("Linked to: ")}
              <span>{property.sharedProfiles.length || 0}</span>
            </p>
          </div>

          <div>
            <ul>
              {property.sharedProfiles.map((assignPropData) => (
                <li className="d-flex linked_li_details">
                  <div className="">
                    <div className="linked_li_div">
                      <p className="linked_li_name">
                        {assignPropData?.tenantid?.fname || ""}{" "}
                        {assignPropData?.tenantid?.lname || ""}
                      </p>
                      <p className="linked_li_score">
                        {_t("Profile")}:{" "}
                        {assignPropData?.tenantid?.paymentScores?.creditScore
                          ?.value || 0}
                      </p>
                    </div>
                    <div className="linked_li_rate">
                      <p>
                        {_t("Max Rent")}: {calculateRentAmount(assignPropData)}{" "}
                        {haveValue(assignPropData?.rent_from_income) &&
                          haveValue(assignPropData?.rent_percentage)
                          ? ` [${assignPropData?.rent_percentage}%]`
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btngreen"
                      onClick={(e) => handleNavigateProfile(assignPropData)}
                    >
                      {_t("Award Property")}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={awardConfirmationModal} className="metermodal" centered onHide={() => { setAwardConfirmationModal(false) }}>
        <Modal.Body>
          <h4>
            {_t("Are you sure?")}
          </h4>
          <div className="auth-form">
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={() => { setAwardConfirmationModal(false) }}
              >
                {_t("No")}
              </button>
              <button
                className="btn btngreen"
                onClick={(e) => awardPropertyHandler()}
              >
                {_t("Yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SingleProperty;
