import React, { useContext, useState, useEffect, useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PhoneNumber from "libphonenumber-js";
import AuthContext from "../../../context/auth/AuthContext";
// import "react-phone-number-input/style.css";
import { IoChevronBack } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import axios from "axios";
import countryList from "react-select-country-list";
import { Button, Col, Modal, Row } from "react-bootstrap";
import successicon from "../../../assets/img/successgif.gif";
import greentick from "../../../assets/img/greentick.png";
import cross from "../../../assets/img/cross-short.png";
import { init, setOptions } from "onfido-sdk-ui";
import Select from "react-select";
import { ImProfile } from "react-icons/im";
import TenantContext from "../../../context/tenant/TenantContext";
import { haveValue } from "../../../helper/common";
import { withAsyncPaginate } from "react-select-async-paginate";
import Creatable from "react-select/creatable";
import { toast } from "react-toastify";
import { HiOutlineTrash } from "react-icons/hi2";
import constants from "../../../Utils/constants";

const EditTenant = () => {
  const { _t } = useTranslator();

  const { hash } = useLocation();
  if (hash === "#") {
    const handleScrollUploadDoc = () => {
      document.getElementsByClassName("accordion-button collapsed")[0].click();
    };
  }

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const {
    isLoggedIn,
    user,
    cardData,
    updateProfile,
    addCard,
    fetchCardDetails,
  } = authContext;
  const [checkCardError, setCheckCardError] = useState([]);

  const options = useMemo(() => {
    return countryList()
      .getData()
      .map((country) => ({
        ...country,
        label: _t(country.label), // Wrap the label with the translation function
        value: country.label,
      }));
  }, [_t]);
  const [uniSelect, setUniSelect] = useState({});
  const [uniCurrSelect, setUniCurrSelect] = useState({});
  const [emailChangeModal, setEmailChangeModal] = useState(false);
  const [countryCode, setCountryCode] = useState("es");

  const handleSelectChange = (e) => {
    setTenantProfile((prevData) => ({
      ...prevData,
      name_of_university: e.label,
    }));
    setUniSelect(e);
  };
  const handleSelectCurrChange = (e) => {
    setTenantProfile((prevData) => ({
      ...prevData,
      name_of_current_university: e.label,
    }));
    setUniCurrSelect(e);
  };

  const [countrySelect, setCountrySelect] = useState({});
  const handleNationalityChange = (e) => {
    setTenantProfile((prevData) => ({ ...prevData, nationality: e.value }));
    setCountrySelect(e);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [onfidoKycStatus, setOnfidoKycStatus] = useState("pending");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [idType, setIdType] = useState("dni_nie");
  const tenantContext = useContext(TenantContext);
  const { saveKycInfo, onfidoKyc, getUniversityListing, checkDuplicateDniNie } =
    tenantContext;

  const [tenantProfile, setTenantProfile] = useState({
    fname: "",
    lname: "",
    linkedin_profile: "",
    dni_nie: "",
    passport_id: "",
    nationality: "",
    current_address: "",
    years_of_living_in_country: "",
    country_code: "",
    phone_number: "",
    employment_status: "",
    type_of_employee: "",
    name_of_company: "",
    position: "",
    type_of_student: "",
    name_of_university: "",
    current_annual_income: "",
    rented_locally_before: "",
    email_previous_employer: "",
    email_previous_landlord: "",
    email_of_university: "",
    type: "tenant",
    email: "",
    duration_type: "",
    have_sharer: "",
    sharer_name: "",
    sharer_email: "",
    sharers: [{ name: "", email: "" }],
    is_freelancer: "false",
    current_course: "",
    name_of_current_university: "",
    university_end_date: "",
    is_university: "",
  });

  const onChangeTenant = (e) => {
    if (e.target.name == "email")
      setTenantProfile({
        ...tenantProfile,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    else
      setTenantProfile({ ...tenantProfile, [e.target.name]: e.target.value });
    //setTenantProfile({ ...tenantProfile, type: "tenant"});
    //console.log(e.target.value);
  };

  const onSubmitTenant = async (e) => {
    e.preventDefault();

    const checkemail = await isValidEmail(tenantProfile?.email);
    if (!checkemail) {
      toast.error(_t("Please enter valid email"));
      return;
    }

    const duplicate_dni = await checkDuplicateDniNie(
      user?._id,
      tenantProfile?.dni_nie
    );

    if (duplicate_dni.success_res.already_present) {
      toast.error(_t("DNI/NIE Already Used By Another Tenant Profile"));
      return;
    }

    if (
      haveValue(tenantProfile?.have_sharer) &&
      tenantProfile?.have_sharer == "yes"
    ) {
      const sharer_temp = tenantProfile?.sharers;
      let all_ok = true;
      let added_myself = false;

      for (let i = 0; i < sharer_temp.length; i++) {
        const temp = sharer_temp[i];
        const checkemail = await isValidEmail(temp.email);
        if (!checkemail) {
          all_ok = false; // Set all_ok to false if any email is invalid
          break; // Exit the loop early
        }
        if (tenantProfile?.email == temp.email) {
          added_myself = true;
          break;
        }
      }
      if (added_myself) {
        toast.error(_t("Cannot add yourself as a Flatmate"));
        return;
      }
      if (!all_ok) {
        toast.error(_t("Please enter valid sharer email format"));
        return;
      }
    }
    if (!haveValue(tenantProfile?.fname) || !haveValue(tenantProfile?.lname)) {
      toast.error(_t("Name cannot be empty!"));
      return;
    }

    if (tenantProfile?.employment_status === constants.student) {
      const checkemail = await isValidEmail(tenantProfile?.email_of_university);
      if (!checkemail) {
        toast.error(_t("Please enter valid university email"));
        return;
      }
    }
    const sendData = { ...tenantProfile };
    sendData.role = "tenant";
    sendData.phone_number = phoneNumber;
    sendData.is_edit = true;
    if (sendData?.have_sharer === "no") {
      sendData.sharer_name = null;
      sendData.sharer_email = null;
    }

    updateProfile(user?._id, sendData);
    navigate("/tenant-profile");
  };

  useEffect(() => {
    if (haveValue(user?.dni_nie)) setIdType("dni_nie");
    else setIdType("passport_id");
    setTenantProfile(user);
    setUniSelect({
      label: user?.name_of_university,
      value: user?.name_of_university,
    });
    setUniCurrSelect({
      label: user?.name_of_current_university,
      value: user?.name_of_current_university,
    });

    const checkCountry = options.filter(
      (val) => val.value == user?.nationality
    );

    if (hash === "#add_flatmate_id") {
      var element = document.getElementById("renting_details");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

    console.log(checkCountry, "checkCountrycheckCountry");
    if (haveValue(checkCountry)) setCountrySelect(checkCountry);
  }, []);

  useEffect(() => {
    if (
      tenantProfile?.phone_number &&
      tenantProfile?.phone_number.charAt(0) == "+"
    ) {
      setPhoneNumber(tenantProfile.phone_number);
    } else {
      if (tenantProfile?.country_code && tenantProfile?.phone_number) {
        let tempNumber =
          tenantProfile?.country_code + tenantProfile?.phone_number;
        setPhoneNumber(tempNumber);
      } else if (!tenantProfile?.country_code && tenantProfile?.phone_number) {
        setPhoneNumber(tenantProfile?.phone_number);
      }
    }
  }, [tenantProfile]);

  const checkKyc = () => {
    let kyc_done = tenantProfile?.onfido_kyc_success;

    if (kyc_done) {
      return <img src={greentick} alt="" />;
    } else {
      return <img src={cross} alt="" />;
    }
  };

  const initiateOnfido = () => {
    setButtonDisable(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        setButtonDisable(false);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        console.log("Error Onfido = ", err);
        setButtonDisable(false);
        setOnfidoKycStatus("server-error");
      });
  };

  async function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const initiateKyc = (token, workFlowRunId) => {
    setButtonDisable(true);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      language: localStorage.getItem("i18nextLng") == "es" ? "es" : "en_US",
      containerId: "onfido-mount",
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        setButtonDisable(false);
        saveOnfidoKycRespone(data);
      },
      onError: function (err) {
        console.log("onfido error happend");
        console.log("ERROR OnFIDO = ", err);
        setButtonDisable(false);
      },
      onUserExit: function (userExitCode) {
        console.log("user exitted", userExitCode);
        setButtonDisable(false);
      },
      onModalRequestClose: function () {
        // Update options with the state of the modal
        setButtonDisable(false);
        onfido.setOptions({ isModalOpen: false });
        console.log("modal closed of onfido");
      },
      //steps: ['welcome', 'poa', 'document', 'face', 'complete'],  //poa-proof of address
      steps: ["welcome", "document", "face", "complete"],
    });
  };

  const saveOnfidoKycRespone = (kycData) => {
    onfidoKyc(user?._id, kycData)
      .then((res) => {
        console.log("promise resolved then response", res);
        setOnfidoKycStatus("under_process");
      })
      .catch((err) => {
        console.log("promise rejected err is ", err);
        setOnfidoKycStatus("failed");
      });
  };

  const onChangeIdType = (e) => {
    setIdType(e.target.value);
    if (e.target.value == "passport_id") {
      setTenantProfile({ ...tenantProfile });
    } else {
      setTenantProfile({ ...tenantProfile });
    }
  };

  const loadOptions = async (search, loadedOptions) => {
    // const response = await fetch(`/awesome-api-url/?search=${search}&offset=${loadedOptions.length}`);
    const results = await getUniversityListing(search, loadedOptions);
    const responseJSON = results.success_res.universityListing.map((res) => ({
      label: res._source.name,
      value: res._source.name,
    }));
    var hasMore = false;
    if (results.success_res.count > loadedOptions.length * 10) {
      hasMore = true;
    }
    return {
      options: responseJSON,
      hasMore: hasMore,
      // hasMore: responseJSON.has_more,
    };
  };

  const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
  const formatCreateLabel = (inputValue) => {
    if (isNaN(Number(inputValue)))
      return (
        <div style={{ width: "100% !important" }}>
          <button
            style={{ width: "100% !important" }}
            className="btn btnorange btn-selectbox"
          >
            {/* <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.22222 13.2222C6.22222 13.4285 6.30417 13.6263 6.45003 13.7722C6.59589 13.9181 6.79372 14 7 14C7.20628 14 7.40411 13.9181 7.54997 13.7722C7.69583 13.6263 7.77778 13.4285 7.77778 13.2222V7.77778H13.2222C13.4285 7.77778 13.6263 7.69583 13.7722 7.54997C13.9181 7.40411 14 7.20628 14 7C14 6.79372 13.9181 6.59589 13.7722 6.45003C13.6263 6.30417 13.4285 6.22222 13.2222 6.22222H7.77778V0.777778C7.77778 0.571498 7.69583 0.373667 7.54997 0.227806C7.40411 0.0819442 7.20628 0 7 0C6.79372 0 6.59589 0.0819442 6.45003 0.227806C6.30417 0.373667 6.22222 0.571498 6.22222 0.777778V6.22222H0.777778C0.571498 6.22222 0.373667 6.30417 0.227806 6.45003C0.0819442 6.59589 0 6.79372 0 7C0 7.20628 0.0819442 7.40411 0.227806 7.54997C0.373667 7.69583 0.571498 7.77778 0.777778 7.77778H6.22222V13.2222Z"
                fill="white"
              />
            </svg>{" "} */}
            {_t("Cant find your Institution? Type and Add")}
          </button>
        </div>
      );
    // Customize the text here
    else return null;
  };

  const handleAddMoreComponent = () => {
    setTenantProfile((prevState) => ({
      ...prevState,
      sharers: [...prevState.sharers, { name: "", email: "" }],
    }));
  };
  const handleSharerChange = (index, e) => {
    const { name, value } = e.target;
    const sharers = [...tenantProfile.sharers];
    sharers[index][name] = value;
    setTenantProfile((prev) => {
      return { ...prev, sharers };
    });
  };

  const removeFlatmate = (index) => {
    const updatedSharers = [...tenantProfile.sharers];

    // Remove the element at the specified index
    updatedSharers.splice(index, 1);
    setTenantProfile((prevState) => ({
      ...prevState,
      sharers: updatedSharers,
    }));
  };

  const checkEmail = (e) => {
    e.preventDefault();
    console.log("COMING HERE ON SUBMIT");
    const ieRegex = /^[a-zA-Z0-9._%+-]+@student\.ie\.edu$/;
    if (ieRegex.test(tenantProfile?.email)) {
      onSubmitTenant(e);
    } else {
      setEmailChangeModal(true);
    }
  };

  const handleRemoveIE = (e) => {
    tenantProfile.is_university = false;
    onSubmitTenant(e);
  };

  console.log(
    document.getElementById("header").offsetHeight,
    "checkheighthere"
  );

  return (
    <>
      {/* <div className='margin-div'></div> */}
      <div className="tabs-box-pattern">
        <div className="tabs-item d-block">
          <form
            onSubmit={(e) =>
              user?.is_university ? checkEmail(e) : onSubmitTenant(e)
            }
            className="position_relative"
          >
            <div
              className="row edittitle blockelement"
              style={{
                top: `${document.getElementById("header").offsetHeight}px`,
              }}
            >
              <div className="col-md-4 col-sm-2 col-6">
                <button
                  type="button"
                  onClick={(e) => navigate("/tenant-profile")}
                  className="backbtn"
                >
                  <IoChevronBack />
                </button>
              </div>
              <div className="col-md-4 col-sm-5 col-6">
                <h4>{_t("Edit Personal info")}</h4>
              </div>
              <div className="col-md-4 col-sm-5 col-12">
                {/* <div className="row mt-3">
                  <div className="col-md-12"> */}
                <div className="btngroupbar">
                  <button type="submit" className="btnsubmit">
                    {_t("Save Changes")}
                  </button>
                </div>
                {/* </div>
                </div> */}
              </div>
            </div>
            <div className="row basicbox orangebox">
              <div className="col-12">
                <h3 className="Property">{_t("Basic")}</h3>
              </div>

              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("First Name")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder="Enter First Name"
                    name="fname"
                    value={tenantProfile?.fname || ""}
                    onChange={onChangeTenant}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Last Name")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter Last Name")}
                    name="lname"
                    onChange={onChangeTenant}
                    value={tenantProfile?.lname || ""}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Nationality")}</label>
                  {/* <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Select Nationality")}
                    name="nationality"
                    onChange={onChangeTenant}
                    value={tenantProfile?.nationality || ""}
                  /> */}

                  <Select
                    className="form-control user-auth uni_select"
                    options={options}
                    value={countrySelect}
                    onChange={handleNationalityChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                {/* <div className="info-wapper">
                  <label>{_t("Id Type")}</label>
                  <select
                    className="custom-input input-selected"
                    placeholder="Select Employment Status"
                    name="employment_status"
                    value={idType || ""}
                    onChange={onChangeIdType}
                  >
                    <option value="dni_nie" checked={idType == 'dni_nie' ? true : false}>{_t("DNI/NIE")}</option>
                    <option value="passport_id" checked={idType == 'passport_id' ? true : false}>{_t("Passport")}</option>
                  </select>
                </div> */}
                <div className="info-wapper">
                  <label>{_t("Passport Number")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter Passport Number")}
                    name="passport_id"
                    onChange={onChangeTenant}
                    value={tenantProfile?.passport_id || ""}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("DNI/NIE/NIF")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter DNI/NIE/NIF")}
                    name="dni_nie"
                    onChange={onChangeTenant}
                    value={tenantProfile?.dni_nie || ""}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Phone Number")}</label>

                  <PhoneInput
                    className="custom-input phone_input"
                    international
                    defaultCountry={countryCode}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Email")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter Email")}
                    name="email"
                    onChange={onChangeTenant}
                    value={tenantProfile?.email || ""}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("LinkedIn Profile")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter LinkedIn Profile")}
                    name="linkedin_profile"
                    onChange={onChangeTenant}
                    value={tenantProfile?.linkedin_profile || ""}
                  />
                </div>
              </div>
            </div>
            <div className="row basicbox graybox">
              <div className="col-12">
                <h3 className="Property">
                  {_t("Digital Verification")}
                  <span className="property_condition">
                    ({_t("Please use original documents")})
                  </span>
                </h3>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper verify-wrap">
                  <label>
                    {_t("Digital Identity Verification")}
                    <span>{_t("(Passport or DNI/NIE)")}</span>
                  </label>
                  <p>
                    {tenantProfile?.onfido_kyc_success
                      ? _t("Completed")
                      : _t("Not Completed")}{" "}
                    {checkKyc()}
                  </p>
                </div>
                {onfidoKycStatus === "pending" && (
                  <>
                    <div className="verificationbtn">
                      <div id="onfido-mount"></div>
                      <button
                        type="button"
                        className="btn-bro connect-bank"
                        onClick={initiateOnfido}
                        disabled={buttonDisable}
                      >
                        {" "}
                        <ImProfile /> {_t("Update Verification")}
                      </button>
                    </div>
                  </>
                )}

                {onfidoKycStatus === "under_process" && (
                  <>
                    <div>
                      <div className="tabs-item d-block">
                        <h4>
                          {_t("Verifying Digital ID")}{" "}
                          <img
                            src={successicon}
                            width={40}
                            height={40}
                            alt=""
                          />{" "}
                        </h4>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row basicbox orangebox">
              <div className="col-12">
                <h3 className="Property">{_t("Educational/Employment")}</h3>
              </div>

              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Employment Status")}</label>
                  <select
                    className="custom-input"
                    placeholder="Select Employment Status"
                    name="employment_status"
                    value={tenantProfile?.employment_status || ""}
                    onChange={onChangeTenant}
                  >
                    {user?.is_university ? (
                      <option value={constants.student}>{_t("Student")}</option>
                    ) : (
                      <>
                        <option value={constants.student}>
                          {_t("Student")}
                        </option>
                        <option value={constants.employed}>
                          {_t("Employed")}
                        </option>
                        <option value={constants.self_employed}>
                          {_t("Self-Employed")}
                        </option>
                        <option value={constants.unemployed}>
                          {_t("Unemployed")}
                        </option>
                      </>
                    )}
                  </select>
                </div>
              </div>
              {tenantProfile?.employment_status === constants.employed && (
                <>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Position")}</label>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder={_t("Enter Position")}
                        name="position"
                        onChange={onChangeTenant}
                        value={tenantProfile?.position || ""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Name Of Employer/Company")}</label>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder={_t("Enter Name Of Employer/Company")}
                        name="name_of_company"
                        onChange={onChangeTenant}
                        value={tenantProfile?.name_of_company || ""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Email Of Previous Employer")}</label>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder={_t("Enter Email Of Previous Employer")}
                        name="email_previous_employer"
                        onChange={onChangeTenant}
                        value={tenantProfile?.email_previous_employer || ""}
                      />
                    </div>
                  </div>
                </>
              )}
              {tenantProfile?.employment_status === constants.self_employed && (
                <>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper employment-type-spacing">
                      <label>{_t("Employment Type")}</label>
                      <div className="customradio_box height-equal">
                        <label className="forminput">
                          <input
                            type="radio"
                            required
                            className="user-auth"
                            name="is_freelancer"
                            checked={
                              tenantProfile.is_freelancer == false ||
                              tenantProfile.is_freelancer == "false"
                                ? true
                                : false
                            }
                            value={false}
                            onChange={onChangeTenant}
                          />
                          {_t("Own a Company")}
                        </label>
                        <label className="forminput">
                          <input
                            type="radio"
                            required
                            className="user-auth"
                            name="is_freelancer"
                            value={true}
                            onChange={onChangeTenant}
                            checked={
                              tenantProfile.is_freelancer == true ||
                              tenantProfile.is_freelancer == "true"
                                ? true
                                : false
                            }
                          />
                          {_t("Freelancer")}
                        </label>
                      </div>
                    </div>
                  </div>
                  {(tenantProfile?.is_freelancer == "false" ||
                    tenantProfile?.is_freelancer == false) && (
                    <div className="col-lg-6 col-12">
                      <div className="info-wapper">
                        <label>{_t("Company Name")}</label>
                        <input
                          type="text"
                          className="custom-input"
                          placeholder={_t("Enter Company Name")}
                          name="name_of_company"
                          onChange={onChangeTenant}
                          value={tenantProfile?.name_of_company || ""}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Annual Income")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter Annual Income")}
                    name="current_annual_income"
                    onChange={onChangeTenant}
                    value={tenantProfile?.current_annual_income || ""}
                  />
                </div>
              </div>

              {tenantProfile?.employment_status === constants.student ? (
                <>
                  <div className="col-12">
                    <div className="info-wapper">
                      <label className="edit-edu-heading">
                        {_t("Current Education Details")}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Education Level")}</label>
                      <select
                        id="degree"
                        name="current_course"
                        value={tenantProfile.current_course}
                        onChange={onChangeTenant}
                        className="custom-input input-selected"
                      >
                        <option value="">Select a degree</option>
                        {user?.is_university ? (
                          <>
                            <option data-key="degree" value="Undergraduate">
                              Undergraduate
                            </option>
                            <option data-key="degree" value="Master">
                              Master
                            </option>
                          </>
                        ) : (
                          <>
                            <option data-key="degree" value="Associate's">
                              Associate's
                            </option>
                            <option data-key="degree" value="Bachelor's">
                              Bachelor's
                            </option>
                            <option data-key="degree" value="Master's">
                              Master's
                            </option>
                            <option data-key="degree" value="Doctorate">
                              Doctorate
                            </option>
                            <option
                              data-key="degree"
                              value="Certificate / Diploma"
                            >
                              Certificate / Diploma
                            </option>
                            <option
                              data-key="degree"
                              value="Graduate Certificates"
                            >
                              Graduate Certificates
                            </option>
                            <option data-key="degree" value="Bootcamps">
                              Bootcamps
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Institution Name")}</label>
                      {user?.is_university ? (
                        <select
                          id="degree"
                          name=""
                          className="custom-input input-selected"
                        >
                          <option value="" selected>
                            IE University, Spain
                          </option>
                        </select>
                      ) : (
                        <CreatableAsyncPaginate
                          value={uniCurrSelect}
                          loadOptions={loadOptions}
                          className="form-control user-auth uni_select"
                          name="name_of_current_university"
                          onChange={handleSelectCurrChange}
                          formatCreateLabel={formatCreateLabel}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Expected Course End Date")}</label>
                      <input
                        type="date"
                        name="university_end_date"
                        value={tenantProfile?.university_end_date}
                        onChange={onChangeTenant}
                        className="custom-input"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Email Of University")}:</label>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder={_t("Enter University Email")}
                        name="email_of_university"
                        onChange={onChangeTenant}
                        disabled={user?.is_university}
                        value={tenantProfile?.email_of_university || ""}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="info-wapper">
                      <label className="edit-edu-heading">
                        {_t("Highest Education Details")}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Education Level")}</label>
                      <select
                        id="degree"
                        name="type_of_student"
                        value={tenantProfile?.type_of_student}
                        onChange={onChangeTenant}
                        className="custom-input input-selected"
                      >
                        {user?.is_university ? (
                          <>
                            <option data-key="degree" value="Undergraduate">
                              Undergraduate
                            </option>
                            <option data-key="degree" value="Master">
                              Master
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="">{_t("Select a degree")}</option>
                            <option data-key="degree" value="Associate's">
                              Associate's
                            </option>
                            <option data-key="degree" value="Bachelor's">
                              Bachelor's
                            </option>
                            <option data-key="degree" value="Master's">
                              Master's
                            </option>
                            <option data-key="degree" value="Doctorate">
                              Doctorate
                            </option>
                            <option
                              data-key="degree"
                              value="Certificate / Diploma"
                            >
                              Certificate / Diploma
                            </option>
                            <option
                              data-key="degree"
                              value="Graduate Certificates"
                            >
                              Graduate Certificates
                            </option>
                            <option data-key="degree" value="Bootcamps">
                              Bootcamps
                            </option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("Institution Name")}</label>
                      {user?.is_university ? (
                        <select
                          id="degree"
                          name=""
                          className="custom-input input-selected"
                        >
                          <option value="" selected>
                            IE University, Spain
                          </option>
                        </select>
                      ) : (
                        <CreatableAsyncPaginate
                          value={uniSelect}
                          loadOptions={loadOptions}
                          className="form-control user-auth uni_select"
                          name="name_of_university"
                          onChange={handleSelectChange}
                          formatCreateLabel={formatCreateLabel}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>
                        {_t("Highest Education/ Currently Pursuing")}
                      </label>
                      <select
                        id="degree"
                        name="type_of_student"
                        value={tenantProfile?.type_of_student}
                        onChange={onChangeTenant}
                        className="custom-input input-selected"
                      >
                        <option value="">Select a degree</option>
                        <option data-key="degree" value="Associate's">
                          Associate's
                        </option>
                        <option data-key="degree" value="Bachelor's">
                          Bachelor's
                        </option>
                        <option data-key="degree" value="Master's">
                          Master's
                        </option>
                        <option data-key="degree" value="Doctorate">
                          Doctorate
                        </option>
                        <option data-key="degree" value="Certificate / Diploma">
                          Certificate / Diploma
                        </option>
                        <option data-key="degree" value="Graduate Certificates">
                          Graduate Certificates
                        </option>
                        <option data-key="degree" value="Bootcamps">
                          Bootcamps
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="info-wapper">
                      <label>{_t("University")}</label>
                      {/* <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter University")}
                    name="name_of_university"
                    onChange={onChangeTenant}
                    value={tenantProfile?.name_of_university || ""}
                  /> */}
                      <CreatableAsyncPaginate
                        value={uniSelect}
                        loadOptions={loadOptions}
                        className="form-control user-auth uni_select"
                        name="name_of_university"
                        onChange={handleSelectChange}
                        formatCreateLabel={formatCreateLabel}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row basicbox graybox" id="renting_details">
              <div className="col-12">
                <h3 className="Property">{_t("Renting Details")}</h3>
              </div>

              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Time Lived In Spain")}</label>
                  <div className="edit-time-living">
                    <input
                      type="text"
                      className="custom-input col-sm-8"
                      placeholder={_t("Enter Duration")}
                      name="years_of_living_in_country"
                      onChange={onChangeTenant}
                      value={tenantProfile?.years_of_living_in_country || ""}
                    />
                    <select
                      className="custom-input col-sm-4"
                      placeholder="Select Duration Type"
                      name="duration_type"
                      value={tenantProfile?.duration_type || ""}
                      onChange={onChangeTenant}
                    >
                      <option value="months">{_t("Months")}</option>
                      <option value="years">{_t("Years")}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Have You Rented Before?")}</label>
                  <select
                    className="custom-input"
                    placeholder="Have You Rented Before?"
                    name="rented_locally_before"
                    value={tenantProfile?.rented_locally_before || ""}
                    onChange={onChangeTenant}
                  >
                    <option value="yes">{_t("Yes")}</option>
                    <option value="no">{_t("No")}</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="info-wapper">
                  <label>{_t("Email Of Previous Landlord:")}</label>
                  <input
                    type="text"
                    className="custom-input"
                    placeholder={_t("Enter Email Of Previous Landlord:")}
                    name="email_previous_landlord"
                    onChange={onChangeTenant}
                    value={tenantProfile?.email_previous_landlord || ""}
                    disabled={
                      tenantProfile?.rented_locally_before == "no"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12" id="add_flatmate_id">
                <div className="info-wapper">
                  <label>{_t("Do you have a Flatmate?")}</label>
                  <div className="select-radio">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="have_sharer"
                        value="yes"
                        onChange={(e) => {
                          onChangeTenant(e);
                          if (
                            !haveValue(tenantProfile?.sharers) ||
                            tenantProfile?.sharers.length == 0
                          )
                            handleAddMoreComponent();
                        }}
                        checked={
                          tenantProfile?.have_sharer === "yes" ? true : false
                        }
                      />
                      <label className="form-check-label">{_t("Yes")}</label>
                    </div>
                    &nbsp; &nbsp;
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="have_sharer"
                        value="no"
                        onChange={onChangeTenant}
                        checked={
                          tenantProfile?.have_sharer === "no" ||
                          !haveValue(tenantProfile?.have_sharer)
                            ? true
                            : false
                        }
                      />
                      <label className="form-check-label">{_t("No")}</label>
                    </div>
                  </div>
                </div>
              </div>
              {tenantProfile?.sharers.map((sharer, index) => (
                <>
                  <label className="flatmate-title">
                    {_t("Flatmate")} {index + 1}
                  </label>
                  <div className={`flatmate_div_${index} row`} index={index}>
                    <div className="col-lg-6 col-12">
                      <div className="info-wapper">
                        <label>{_t("Name Of Flatmate")}</label>
                        <input
                          type="text"
                          className="custom-input"
                          placeholder={_t("Enter Name Of Flatmate")}
                          name="name"
                          value={sharer.name}
                          onChange={(e) => handleSharerChange(index, e)}
                          disabled={
                            tenantProfile?.have_sharer == "yes" ? false : true
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="flatmate-rybar">
                        <div className="info-wapper">
                          <label>{_t("Email Of Flatmate")}</label>
                          <input
                            type="text"
                            className="custom-input"
                            placeholder={_t("Enter Email Of Flatmate")}
                            name="email"
                            value={sharer.email}
                            onChange={(e) => handleSharerChange(index, e)}
                            disabled={
                              tenantProfile?.have_sharer == "yes" ? false : true
                            }
                          />
                        </div>
                        <div className="iconslot">
                          <HiOutlineTrash
                            onClick={(e) => removeFlatmate(index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <button
              className="btn btnoutline"
              type="button"
              onClick={handleAddMoreComponent}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4616 7.46154H8.53862V1.53846C8.53862 1.39565 8.4819 1.25869 8.38092 1.15771C8.27994 1.05673 8.14298 1 8.00018 1C7.85737 1 7.72041 1.05673 7.61944 1.15771C7.51846 1.25869 7.46173 1.39565 7.46173 1.53846V7.46154H1.5388C1.396 7.46154 1.25904 7.51827 1.15806 7.61925C1.05708 7.72023 1.00035 7.85719 1.00035 8C0.997696 8.06998 1.01007 8.13972 1.03664 8.20451C1.06321 8.26931 1.10336 8.32765 1.15439 8.37562C1.20542 8.42358 1.26613 8.46005 1.33244 8.48257C1.39875 8.50509 1.46912 8.51313 1.5388 8.50615H7.46173V14.4615C7.46173 14.6043 7.51846 14.7413 7.61944 14.8423C7.72041 14.9433 7.85737 15 8.00018 15C8.14298 15 8.27994 14.9433 8.38092 14.8423C8.4819 14.7413 8.53862 14.6043 8.53862 14.4615V8.53846H14.4616C14.6044 8.53846 14.7413 8.48173 14.8423 8.38075C14.9433 8.27977 15 8.14281 15 8C15 7.85719 14.9433 7.72023 14.8423 7.61925C14.7413 7.51827 14.6044 7.46154 14.4616 7.46154Z"
                  fill="#253837"
                  stroke="#253837"
                />
              </svg>
              {_t("Add More")}
            </button>
          </form>
        </div>
      </div>

      <Modal
        show={emailChangeModal}
        onHide={(e) => setEmailChangeModal(false)}
        centered
        className="newmodalui"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("Are You Sure You Want To Continue?")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="auth-form">
            <p className="skip_modal">
              {_t(
                "You are about to migrate from a student profile into a regular account. You will be required to re-generate your scoring."
              )}
            </p>
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={(e) => setEmailChangeModal(false)}
              >
                {_t("Cancel")}
              </button>
              <button
                className="btn btnorange"
                type="button"
                onClick={handleRemoveIE}
              >
                {_t("Continue")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTenant;
